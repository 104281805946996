export const WHATSAPP_ERROR_MESSAGE = 'Número de Whatsapp não cadastrado.';

export const WHATSAPP_IN_USE = 'Número de Whatsapp em uso.';

export const passwordErrors = {
  min: 'A senha precisa conter pelo menos 8 caracteres.',
  uppercase: 'A senha deve conter pelo menos 1 letra(s) maiúscula(s).',
  lowercase: 'A senha deve conter pelo menos 1 letra(s) minúscula(s).',
  number: 'A senha deve conter pelo menos 1 número(s).',
  specialChar: 'A senha deve conter pelo menos 1 caracter(es) especial(is).',
};

export const whatsappValidationEnabled =
  process.env.REACT_APP_WHATSSAPP_VALIDATION_ENABLED !== 'false' &&
  !!process.env.REACT_APP_WHATSSAPP_VALIDATION_ENABLED;

export const CELLPHONE_ALREADY_REGISTERED =
  'Esse número de celular já foi validado por outro usuário.';
