import { format } from 'date-fns';
import { delay } from '@/utils/helpers';
import { AuthenticatedHttp } from '@/http/axios';
import { parsePaginationResponse } from '@/utils/api';

export const getWithdrawals = async ({ page, search, start_date, end_date }) =>
  AuthenticatedHttp.get('/withdrawals/', {
    params: {
      page,
      search,
      createdAt__gte: format(new Date(start_date), 'yyyy-MM-dd'),
      createdAt__lte: format(new Date(end_date), 'yyyy-MM-dd'),
    },
  }).then(parsePaginationResponse);

export const getBalance = async () =>
  AuthenticatedHttp.get('/financial/balance/').then((response) => response.data);

export const getDataCompany = async () =>
  AuthenticatedHttp.get('/financial/company/')
    .then((response) => {
      const regex = /\/([^/]+)$/;

      const files = response.data.files.map((data) => ({
        ...data,
        path: data?.file || '',
        name: data?.file.match(regex)[1] || '',
      }));

      const _response = { ...response.data, files };

      return _response;
    })
    .catch(({ response }) => ({ data: response?.data }));

export const doWithdrawal = async ({ amount, codeToken }) => {
  const params = codeToken
    ? { amount, mfa_code: codeToken }
    : {
        amount,
      };
  return AuthenticatedHttp.post('/financial/withdraw/', params).then((response) => response.data);
};

export const anticipatePendingAmount = async ({ amount, codeToken }) => {
  const params = codeToken
    ? { amount, mfa_code: codeToken }
    : {
        amount,
      };
  return AuthenticatedHttp.post('/financial/anticipate/', params).then((response) => response.data);
};

export const getPixKey = async () =>
  AuthenticatedHttp.get('/financial/pix/').then((response) => response.data);

export const savePixKey = async ({ pix }) =>
  AuthenticatedHttp.post('/financial/pix/', { pix }).then((response) => response.data);

// TODO REMOVER CASO NÃO TENHA MAIS CADASTRO DE DADOS BANCÁRIOS
export const saveAccountData = async (data) =>
  AuthenticatedHttp.post('/financial/bank_account/', { ...data, legalName: 'teste' }).then(
    (response) => response.data
  );

export const getFee = async () =>
  AuthenticatedHttp.get('/financial/fee/').then((response) => response.data);

export const updateCompany = async (data) =>
  AuthenticatedHttp.put(`/financial/company/`, { ...data }).then((response) => response.data);

export const addDocumentOnCompany = async ({ files, submitForApproval = false }) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append(`files`, file);
  });

  formData.append('submitForApproval', submitForApproval);

  const { data } = await AuthenticatedHttp.put('/financial/company/add_files/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

export const deleteDocumentOnCompany = async (id) =>
  AuthenticatedHttp.delete(`/financial/company/delete_file/${id}/`).then(
    (response) => response.data
  );

export const getWithdrawalFee = async () =>
  AuthenticatedHttp.get(`/financial/withdraw_fee/`).then((response) => response.data);

export const verifyDocumentsService = async () =>
  AuthenticatedHttp.post(`/financial/verification/`).then((response) => response.data);

// to remove
// eslint-disable-next-line prefer-const
let creditCards = [
  {
    id: '1e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'John Doe',
    number: '4111111111111111',
    cvv: '123',
    expirationDate: '12/2025',
    limit: 5000,
    type: 'physical',
    name: 'Visa Gold',
    transactions: [
      {
        id: '2e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-01-01',
        place: 'Supermarket',
        value: 150.75,
      },
      {
        id: '3e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-01-05',
        place: 'Gas Station',
        value: 50.0,
      },
    ],
  },
  {
    id: '4e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Jane Smith',
    number: '5500000000000004',
    cvv: '456',
    expirationDate: '11/2026',
    limit: 7000,
    type: 'virtual',
    name: 'MasterCard Platinum',
    transactions: [
      {
        id: '5e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-02-10',
        place: 'Restaurant',
        value: 200.0,
      },
      {
        id: '6e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-02-15',
        place: 'Online Store',
        value: 120.5,
      },
    ],
  },
  {
    id: '7e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Alice Johnson',
    number: '6011000000000004',
    cvv: '789',
    expirationDate: '10/2027',
    limit: 6000,
    type: 'physical',
    name: 'Discover It',
    transactions: [
      {
        id: '8e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-03-01',
        place: 'Bookstore',
        value: 75.25,
      },
      {
        id: '9e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-03-05',
        place: 'Coffee Shop',
        value: 15.0,
      },
    ],
  },
  {
    id: '10e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Bob Brown',
    number: '30000000000004',
    cvv: '012',
    expirationDate: '09/2025',
    limit: 4000,
    type: 'virtual',
    name: 'Diners Club International',
    transactions: [
      {
        id: '11e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-04-01',
        place: 'Hotel',
        value: 300.0,
      },
      {
        id: '12e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-04-10',
        place: 'Airline',
        value: 500.0,
      },
    ],
  },
  {
    id: '13e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Charlie Davis',
    number: '3530111333300000',
    cvv: '345',
    expirationDate: '08/2026',
    limit: 8000,
    type: 'physical',
    name: 'JCB Standard',
    transactions: [
      {
        id: '14e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-05-01',
        place: 'Gym',
        value: 100.0,
      },
      {
        id: '15e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-05-05',
        place: 'Pharmacy',
        value: 30.0,
      },
    ],
  },
  {
    id: '16e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Diana Evans',
    number: '4000000000000002',
    cvv: '678',
    expirationDate: '07/2025',
    limit: 9000,
    type: 'virtual',
    name: 'Visa Classic',
    transactions: [
      {
        id: '17e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-06-01',
        place: 'Supermarket',
        value: 150.0,
      },
      {
        id: '18e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-06-10',
        place: 'Gas Station',
        value: 60.0,
      },
    ],
  },
  {
    id: '19e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Ethan Foster',
    number: '5105105105105100',
    cvv: '901',
    expirationDate: '06/2026',
    limit: 10000,
    type: 'physical',
    name: 'MasterCard World',
    transactions: [
      {
        id: '20e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-07-01',
        place: 'Restaurant',
        value: 250.0,
      },
      {
        id: '21e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-07-05',
        place: 'Online Store',
        value: 130.0,
      },
    ],
  },
  {
    id: '22e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Fiona Green',
    number: '6011111111111117',
    cvv: '234',
    expirationDate: '05/2026',
    limit: 11000,
    type: 'virtual',
    name: 'Discover More',
    transactions: [
      {
        id: '23e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-08-01',
        place: 'Bookstore',
        value: 80.0,
      },
      {
        id: '24e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-08-05',
        place: 'Coffee Shop',
        value: 20.0,
      },
    ],
  },
  {
    id: '25e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'George Harris',
    number: '30569309025904',
    cvv: '567',
    expirationDate: '04/2025',
    limit: 12000,
    type: 'physical',
    name: 'Diners Club Premier',
    transactions: [
      {
        id: '26e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-09-01',
        place: 'Hotel',
        value: 350.0,
      },
      {
        id: '27e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-09-10',
        place: 'Airline',
        value: 550.0,
      },
    ],
  },
  {
    id: '28e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
    holder: 'Hannah Irving',
    number: '3566002020360505',
    cvv: '890',
    expirationDate: '03/2026',
    limit: 13000,
    type: 'virtual',
    name: 'JCB Gold',
    transactions: [
      {
        id: '29e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-10-01',
        place: 'Gym',
        value: 120.0,
      },
      {
        id: '30e7d1f4e-8f3b-4a2e-9b1e-1a2b3c4d5e6f',
        date: '2023-10-05',
        place: 'Pharmacy',
        value: 40.0,
      },
    ],
  },
];

export const getCreditCards = async () => {
  await delay(1000);

  return Promise.resolve(creditCards);
};

export const getCreditCard = async (id) => {
  await delay(500);
  const creditCard = creditCards.find((card) => card.id === id);
  return Promise.resolve(creditCard);
};

export const createCreditCardService = async (creditCard) => {
  await delay(500);
  creditCard.id = `test-id-${creditCards.length + 1}`;
  creditCard.transactions = [];
  creditCards.push(creditCard);
  return Promise.resolve(creditCard);
};

export const updateCreditCardService = async (id, updatedData) => {
  await delay(500);
  creditCards = creditCards.map((card) => (card.id === id ? { ...card, ...updatedData } : card));
  return Promise.resolve(creditCards.find((card) => card.id === id));
};

export const deleteCreditCardService = async (id) => {
  await delay(500);
  creditCards = creditCards.filter((card) => card.id !== id);
  return Promise.resolve(id);
};

export const getSubscriptions = async () => {
  const { data } = await AuthenticatedHttp.get('/financial/plan_fee/list_plans/');

  const subscriptions = data.map((plan) => ({
    id: String(plan.id),
    title: plan.name,
    transactionFee: plan.creditCardPercentage ?? 0,
    settlementPeriod: plan.creditCardRelease ?? 0,
    securityReserve: plan.creditCardReserve ?? 0,
    cardAntiFraudTransactionFee: plan.creditCardFixed ?? 0,
    isRecomended: plan.name?.toLowerCase().includes('recomendado') || false,
  }));

  return subscriptions;
};


export const getActiveSubscription = async () => {
  const { data } = await AuthenticatedHttp.get('/financial/plan_fee/current_plan/');

  return data.id;
};

export const postSubscription = async (planType) => {
  const { data } = await AuthenticatedHttp.post('/financial/plan_fee/choose_plan/', {
    plan_type: planType,
  });
  return data;
};


let pixKeys = [
  {
    id: '1',
    type: 'cpf',
    value: '12084668018',
    status: 'registered',
    createdAt: '2025-01-02T00:00:00Z',
  },
  {
    id: '2',
    type: 'cnpj',
    value: '63529559000123',
    status: 'registered',
    createdAt: '2025-01-01T00:00:00Z',
  },
];

export const getFinancialPixKeys = async () => {
  await delay(500);

  return Promise.resolve(pixKeys);
};

export const deletePixKeyService = async (id) => {
  await delay(500);
  pixKeys = pixKeys.filter((pixKey) => pixKey.id !== id);
  return Promise.resolve(id);
};

export const createPixKeyService = async (pixKey) => {
  await delay(500);
  pixKeys.id = `test-id-${pixKeys.length + 1}`;
  pixKeys.push(pixKey);
  return Promise.resolve(pixKey);
};

const billetData = {
  code: '34191.09008 63571.277308 71444.640008 3 85350000000000',
  expirationDate: '2025-10-01T00:00:00Z',
  value: 100.0,
  issuer: 'Banco do Brasil',
  payerName: 'Fulano de Tal',
};

export const getBilletCodeService = async (file) => {
  await delay(500);

  return Promise.resolve(billetData.code);
};

export const readBilletDataService = async (code) => {
  await delay(500);

  return Promise.resolve(billetData);
};
