import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cpf } from 'cpf-cnpj-validator';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { fetchAddressByZipCodeService } from '../services/address';
import {
  addDocumentOnCompany,
  updateCompany,
  deleteDocumentOnCompany,
  getDataCompany,
} from '../services/financial';
import { validateCNPJ } from '../utils/validate';

yup.addMethod(yup.string, 'isZipCode', function isZipCode(message) {
  return this.test('isZipCode', message, function test(value) {
    const { path, createError } = this;
    return value && /^\d{5}-?\d{3}$/.test(value) ? true : createError({ path, message });
  });
});

yup.addMethod(yup.string, 'isCpf', function isCpf(message) {
  return this.test('isCpf', message, function test(value) {
    const { path, createError } = this;
    return value && cpf.isValid(value) ? true : createError({ path, message });
  });
});

export const IdentityVerificationContext = createContext({
  form: {},
  companyData: {},
  step: 0,
  setStep: () => {},
  send: () => {},
  addDocuments: () => {},
  deleteDocuments: () => {},
  isFetchingDocuments: false,
  isLoadingCompany: false,
  sending: false,
  fetchAddress: () => {},
  isFetchingCompany: () => {},
  isFetchingAddress: false,
});

export const DocumentType = {
  CPF: {
    label: 'CPF',
    value: 'cpf',
  },
  CNPJ: {
    label: 'CNPJ',
    value: 'cnpj',
  },
};

const IdentityVerificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    documentType: yup
      .string()
      .oneOf([DocumentType.CPF.value, DocumentType.CNPJ.value])
      .required('Campo obrigatório'),
    noLastName: yup.boolean(),
    name: yup
      .string()
      .required('Campo obrigatório')
      .when('noLastName', {
        is: true,
        then: (sch) => sch,
        otherwise: (sch) => sch.matches(/^(\S+\s+){1,}\S+$/, 'Informe o nome completo'),
      }),
    cpf: yup.string().required('Campo obrigatório').isCpf('CPF inválido'),
    phone: yup.string().required('Campo obrigatório'),
    birthDate: yup
      .string()
      .required('Campo obrigatório')
      .test('maiorIdade', 'Idade mínima deve ser 18 anos', (value, context) => {
        const today = new Date();
        const birthDate = new Date(value?.split('/').reverse().join('-'));

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();

        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
          age -= 1;
        }

        return age >= 18;
      }),
    motherName: yup.string().required('Campo obrigatório'),
    cep: yup.string().required('Campo obrigatório').isZipCode('CEP inválido'),
    address: yup.string().required('Campo obrigatório'),
    neighborhood: yup.string().required('Campo obrigatório'),
    number: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    state: yup
      .string()
      .required('Campo obrigatório')
      .max(2, 'Inserir apenas a sigla do estado. Ex.: SP, BA, RJ..'),
    complement: yup.string(),
    cnpj: yup.string().when('documentType', {
      is: DocumentType.CNPJ.value,
      then: yup
        .string()
        .required('Campo obrigatório')
        .test('validCnpj', 'CNPJ inválido', async (value) => validateCNPJ(value)),
    }),

    companyName: yup.string().when('documentType', {
      is: DocumentType.CNPJ.value,
      then: yup.string().required('Campo obrigatório'),
    }),
    companyDocuments: yup.array().when('documentType', {
      is: DocumentType.CNPJ.value,
      then: yup
        .array()
        .of(yup.mixed().required('Campo obrigatório'))
        .min(1, 'Você deve enviar pelo menos 1 arquivo')
        .max(5, 'Você deve enviar no máximo 5 arquivos'),
    }),
    autorizeCompanyManager: yup.boolean().when('documentType', {
      is: DocumentType.CNPJ.value,
      then: yup.boolean().oneOf([true], 'Você deve aceitar os termos'),
    }),
    acceptedTerms: yup.boolean().oneOf([true], 'Você deve aceitar os termos'),
  });

  const {
    data: companyData,
    isFetching: isLoadingCompany,
    refetch: isFetchingCompany,
  } = useQuery({
    queryKey: ['financial.company'],
    queryFn: () => getDataCompany(),
    staleTime: 0,
    onError: () => {
      queryClient.removeQueries(['financial.company']);
    },
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      documentType:
        companyData?.type === 'company' ? DocumentType.CNPJ.value : DocumentType.CPF.value,
      noLastName: companyData?.noLastName || false,
      name: companyData?.completeName || '',
      cpf: companyData?.cpf || '',
      phone: companyData?.phone || '',
      birthDate: companyData?.birthDate || '',
      motherName: companyData?.motherName || '',
      cep: companyData?.cep || '',
      address: companyData?.street || '',
      number: companyData?.number || '',
      complement: companyData?.complement || '',
      city: companyData?.city || '',
      state: companyData?.state || '',
      neighborhood: companyData?.neighborhood || '',
      cnpj: companyData?.cnpj || '',
      averageRevenue: companyData?.averageRevenue || '',
      companyName: companyData?.companyName || '',
      companyType: companyData?.companyType || 'INDIVIDUAL',
      companyDocuments: companyData?.files || [],
      autorizeCompanyManager: companyData?.autorizeCompanyManager || false,
      acceptedTerms: companyData?.acceptedTerms || false,
    },
  });

  const { refetch: fetchAddress, isFetching: isFetchingAddress } = useQuery({
    enabled: false,
    queryFn: () => fetchAddressByZipCodeService(form.getValues('cep').replace('-', '')),
    queryKey: ['zipcode-address'],
    onSuccess: ({ state, neighborhood, city, address }) => {
      form.setValue('address', address);
      form.setValue('neighborhood', neighborhood);
      form.setValue('city', city);
      form.setValue('state', state);
      form.clearErrors(['address', 'city', 'state', 'neighborhood', 'cep']);
    },
    onError: () => {
      form.setError('cep', {
        type: 'manual',
        message: 'CEP não encontrado',
      });
    },
  });

  const [step, setStep] = useState(0);

  const { mutateAsync: send, isLoading: sending } = useMutation({
    mutationFn: (data) => updateCompany(data),
    onSuccess: () => {
      queryClient.invalidateQueries('financial.company');
    },
    onError: (error) => {
      const message = error?.response?.data?.detail || 'Erro ao enviar dados.';
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const { mutateAsync: addDocuments, isLoading: isFetchingDocuments } = useMutation({
    mutationFn: (data) => addDocumentOnCompany(data),
    onSuccess: () => {
      queryClient.invalidateQueries('financial.company');
      enqueueSnackbar('Dados enviados com sucesso', { variant: 'success' });
    },
    onError: (error) => {
      const message = error?.response?.data?.detail || 'Erro ao enviar os arquivos.';
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const { mutateAsync: deleteDocuments, isLoading: isFetchingDeletingDocuments } = useMutation({
    mutationFn: (data) => deleteDocumentOnCompany(data),
    onSuccess: () => {
      queryClient.invalidateQueries('financial.company');
      enqueueSnackbar('Arquivo excluído com sucesso', { variant: 'success' });
    },
    onError: (error) => {
      const message = error?.response?.data?.detail || 'Erro ao excluir os arquivos.';
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const value = useMemo(
    () => ({
      form,
      companyData,
      addDocuments,
      isLoadingCompany,
      deleteDocuments,
      isFetchingDocuments: isFetchingDocuments || isFetchingDeletingDocuments,
      step,
      setStep,
      send,
      sending,
      fetchAddress,
      isFetchingCompany,
      isFetchingAddress,
    }),
    [
      fetchAddress,
      addDocuments,
      isLoadingCompany,
      deleteDocuments,
      isFetchingDocuments,
      isFetchingDeletingDocuments,
      companyData,
      form,
      isFetchingAddress,
      send,
      sending,
      isFetchingCompany,
      step,
    ]
  );

  return (
    <IdentityVerificationContext.Provider value={value}>
      {children}
    </IdentityVerificationContext.Provider>
  );
};

IdentityVerificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IdentityVerificationProvider;
