import { AuthenticatedHttp } from '@/http/axios';
import { uploadIfNecessary } from './upload-image';

export const createCourseV2Service = async ({ courseId }) =>
  AuthenticatedHttp.post(`/members/v2/${courseId}/`);

export const updateCourseService = async ({ courseId, name, coverImage }) => {
  await uploadIfNecessary({ url: `/members/v2/${courseId}/logo/`, image: coverImage });

  return AuthenticatedHttp.put(`/members/v2/${courseId}/`, {
    nome: name,
  });
};

export const getMemberAreaCourseV2 = async (courseId) =>
  AuthenticatedHttp.get(`/members/v2/${courseId}`)
    .then((response) => ({
      name: response.data.nome,
      coverImage: {
        preview: response.data.capa,
      },
      modules: response?.data?.modulos
        .filter((module) => module !== null)
        ?.map((module) => ({
          id: module?.id,
          name: module?.nome,
          capa: module?.capa,
          coverImage: {
            preview: module?.capa,
          },
          order: module?.posicao,
          lessons:
            module?.aulas?.map?.((lesson) => ({
              id: lesson?.id,
              title: lesson?.nome,
              thumbnail: {
                preview: lesson?.capa,
              },
              order: lesson?.posicao,
              type: 'lesson',
              status: 'published',
            })) || [],
        })),
    }))
    .catch(() => false);

export const deleteCourseV2Service = async ({ courseId }) =>
  AuthenticatedHttp.delete(`/members/v2/${courseId}/`);
