import { useEffect, useState } from 'react';

// routes
import SvgColor from '../../../components/svg-color';
import { PATH_DASHBOARD } from '../../../routes/paths';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_new_dashboard'),
  mySales: icon('ic_currency'),
  collaborators: icon('ic_user'),
  financial: icon('ic_finance'),
  apps: icon('ic_external'),
  reports: icon('ic_reports'),
  products: icon('ic_box'),
  notifications: icon('ic_chat'),
  affiliates: icon('ic_affiliate'),
  subscriptions: icon('ic_dollar'),
  coupons: icon('ic_new_coupon'),
  quiz: icon('ic_new_quiz'),
  integrations: icon('ic_integrations'),
  cart: icon('ic_solar_cart'),
};

const getNavConfig = (token) => [
  // Inicio
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.home, icon: ICONS.dashboard },
      {
        title: 'Produtos',
        path: `${PATH_DASHBOARD.general.products.root}?tab=products`,
        icon: ICONS.products,
      },
      // TODO - it needs integration with backend (no endpoints ready yet) {
      //   title: 'Vitrine',
      //   path: PATH_DASHBOARD.general.showcase,
      //   icon: ICONS.cart,
      // },
      {
        title: 'Minhas vendas',
        path: `${PATH_DASHBOARD.general.mySales}?tab=paid`,
        icon: ICONS.mySales,
      },
      {
        title: 'Assinaturas',
        path: `${PATH_DASHBOARD.general.subscriptions}?tab=active`,
        icon: ICONS.subscriptions,
      },
      // {
      //   title: 'Colaboradores',
      //   path: PATH_DASHBOARD.general.collaborators,
      //   icon: ICONS.collaborators,
      // },
      { title: 'Relatórios', path: PATH_DASHBOARD.general.reports.root, icon: ICONS.reports },

      {
        title: 'Afiliados',
        path: `${PATH_DASHBOARD.general.affiliates}?tab=active`,
        icon: ICONS.affiliates,
      },
      { title: 'Financeiro', path: PATH_DASHBOARD.general.financial, icon: ICONS.financial },
      { title: 'Integrações', path: PATH_DASHBOARD.general.apps, icon: ICONS.integrations },
      // { title: 'Notificações', path: PATH_DASHBOARD.notifications.root, icon: ICONS.notifications },
      { title: 'Cupons', path: PATH_DASHBOARD.coupons.root, icon: ICONS.coupons },
      {
        title: 'Quiz',
        path: `${process.env.REACT_APP_QUIZ_URL}?token=${token}`,
        icon: ICONS.quiz,
        isExternalLink: true,
        tag: {
          label: 'Novo',
          color: 'success',
        },
      },
    ],
  },
];

const Navigation = () => {
  const [token, setToken] = useState('');

  const storedToken = localStorage.getItem('accessToken');

  useEffect(() => {
    setToken(storedToken || '');
  }, [storedToken]);

  return getNavConfig(token);
};

export default Navigation;
