import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, TextField } from '@mui/material';
// hooks
import useEventListener from '../../hooks/useEventListener';

// ----------------------------------------------------------------------

RHFCodes.propTypes = {
  keyName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputs: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  defaultValue: PropTypes.string, // Adicione a prop defaultValue
};

export default function RHFCodes({
  keyName = '',
  inputs = [],
  defaultValue = '',
  disabled = false,
  onChange,
  type,
  ...other
}) {
  const codesRef = useRef(null);
  const { control, setValue } = useFormContext();

  // Usar useEffect para definir os valores iniciais
  useEffect(() => {
    if (defaultValue) {
      inputs.forEach((input, index) => {
        setValue(`${keyName}${index + 1}`, defaultValue[index] || '');
      });
    }
  }, [defaultValue, inputs, setValue, keyName]);

  const handlePaste = (event) => {
    let data = event.clipboardData.getData('text');
    data = data.split('')?.map((item) => {
      if (type === 'number') {
        if (!/^\d?$/.test(item)) {
          return '';
        }
      }

      return item;
    });
    inputs.map((input, index) => setValue(input, data[index]));
    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;

    if (type === 'number') {
      if (!/^\d?$/.test(value)) {
        event.target.value = '';
        return;
      }
    }

    const fieldIndex = name.replace(keyName, '');
    const fieldIntIndex = Number(fieldIndex);

    const nextfield = document.querySelector(`input[name=${keyName}${fieldIntIndex + 1}]`);

    if (value.length > maxLength) {
      event.target.value = value[0];
    }

    if (value.length >= maxLength && fieldIntIndex < inputs.length && nextfield !== null) {
      nextfield.focus();
    }

    handleChange(event);
  };

  const handleBackspace = (event) => {
    const { value, name } = event.target;

    if (event.key === 'Backspace' && value === '') {
      const fieldIndex = name.replace(keyName, '');
      const fieldIntIndex = Number(fieldIndex);

      const prevField = document.querySelector(`input[name=${keyName}${fieldIntIndex - 1}]`);

      if (prevField) {
        prevField.focus();
      }
    }
  };

  useEventListener('paste', handlePaste, codesRef);

  return (
    <Stack direction="row" spacing={2} justifyContent="center" ref={codesRef}>
      {inputs.map((name, index) => (
        <Controller
          key={name}
          name={`${keyName}${index + 1}`}
          control={control}
          disabled={disabled}
          defaultValue={defaultValue[index] || ''} // Define o valor padrão para cada campo
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              autoFocus={index === 0}
              placeholder="-"
              onKeyDown={handleBackspace}
              onChange={(event) => {
                if (onChange) {
                  onChange(event);
                }
                handleChangeWithNextField(event, field.onChange);
              }}
              onFocus={(event) => event.currentTarget.select()}
              InputProps={{
                sx: {
                  width: { xs: 36, sm: 56 },
                  height: { xs: 36, sm: 56 },
                  '& input': { p: 0, textAlign: 'center' },
                },
              }}
              inputProps={{
                maxLength: 1,
              }}
              {...other}
            />
          )}
        />
      ))}
    </Stack>
  );
}
