import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { matchIsValidTel } from 'mui-tel-input';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '@/auth/useAuthContext';
import {
  validateEmail,
  validateWhatsapp,
  requestUserEmailToken,
  requestUserWhatsappToken,
  updateUserService,
} from '@/services/user';
import { whatsappValidationEnabled } from '@/consts/auth';
import { validationSchema } from './validations';

export default function useVerifyModal({ onSuccessWhatsapp = () => {} }) {
  const [emailError, setEmailError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');
  const [requestEmailTokenSuccessMessage, setRequestEmailTokenSuccess] = useState('');
  const [requestWhatsappSuccessMessage, setRequestWhatsappSuccessMessage] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState();

  const { userCellphone } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { isLoading: isLoadingRequestEmailToken, mutateAsync: requestEmailToken } = useMutation({
    onMutate() {
      setEmailError('');
      setRequestEmailTokenSuccess('');
    },
    mutationFn: requestUserEmailToken,
    onSuccess(result) {
      setRequestEmailTokenSuccess(result?.data?.detail);
    },
    onError(error) {
      const detail = error.response.data?.detail;

      const translatedMessage = detail.replace(
        /^Pedido foi limitado\. Expected available in (\d+) seconds\.$/,
        'Pedido foi limitado. Estará disponível novamente em $1 segundos.'
      );
      setEmailError(translatedMessage);
    },
  });

  const { isLoading: isloadingRequestWhatsappToken, mutate: requestWhatsappToken } = useMutation({
    onMutate() {
      setWhatsappError('');
      setRequestWhatsappSuccessMessage('');
    },
    mutationFn: requestUserWhatsappToken,
    onSuccess(result) {
      setRequestWhatsappSuccessMessage(result?.data?.detail);
    },
    onError(error) {
      const detail = error.response.data?.detail;

      const translatedMessage = detail.replace(
        /^Pedido foi limitado\. Expected available in (\d+) seconds\.$/,
        'Pedido foi limitado. Estará disponível novamente em $1 segundos.'
      );
      setWhatsappError(translatedMessage);
    },
  });

  const { isLoading: isLoadingValidateEmail, mutate: mutateValidateEmail } = useMutation({
    onMutate() {
      setEmailError('');
    },
    mutationFn: validateEmail,
    onSuccess() {
      if (!whatsappValidationEnabled) {
        onSuccessWhatsapp();
      }
    },
    onError(error) {
      setEmailError(error.response.data.detail);
    },
  });

  const { isLoading: isLoadingValidateWhatsapp, mutate: mutateValidateWhatsapp } = useMutation({
    onMutate() {
      setWhatsappError('');
    },
    mutationFn: validateWhatsapp,
    onSuccess() {
      onSuccessWhatsapp();
    },
    onError(error) {
      setWhatsappError(error.response.data.detail);
    },
  });

  const onSubmit = (values) => {
    const { isToValidateEmail, isToValidateWhatsapp } = values;

    if (isToValidateEmail) {
      const { email1, email2, email3, email4, email5, email6 } = values;
      const token = `${email1}${email2}${email3}${email4}${email5}${email6}`;
      mutateValidateEmail(token);
    }

    if (isToValidateWhatsapp) {
      const { whatsapp1, whatsapp2, whatsapp3, whatsapp4, whatsapp5, whatsapp6 } = values;
      const token = `${whatsapp1}${whatsapp2}${whatsapp3}${whatsapp4}${whatsapp5}${whatsapp6}`;
      mutateValidateWhatsapp(token);
    }
  };

  const updateCellphone = async () => {
    const phoneCountryCode = methods.getValues('phoneCountryCode');
    const cellphone = methods.getValues('cellphone');

    const isValidPhone = matchIsValidTel(`+${phoneCountryCode}${cellphone}`);

    if (isValidPhone) {
      try {
        await updateUserService({ phoneCountryCode, cellphone });
        setWhatsappNumber(cellphone);
      } catch (error) {
        methods.setError('cellphone', { message: error?.message });
      }
    } else {
      methods.setError('cellphone', { message: 'Número de telefone inválido' });
    }
  };

  const { mutateAsync: updadeCellphoneMutate, isLoading: isLoadingUpdateCellphone } = useMutation({
    mutationFn: updateCellphone,
    onSuccess() {
      enqueueSnackbar('Número de whatdsapp atualizado com sucesso!', {
        variant: 'success',
      });
    },
    onError() {
      enqueueSnackbar('Ocorreu um erro ao tentar atualizar o número de whatsapp!', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    setWhatsappNumber(userCellphone);
  }, [userCellphone]);

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
    isLoading:
      isLoadingValidateEmail ||
      isLoadingValidateWhatsapp ||
      isLoadingRequestEmailToken ||
      isloadingRequestWhatsappToken,
    emailError,
    whatsappError,
    requestEmailToken,
    requestWhatsappToken,
    requestEmailTokenSuccessMessage,
    requestWhatsappSuccessMessage,
    updadeCellphoneMutate,
    isLoadingUpdateCellphone,
    whatsappNumber,
  };
}
