import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState, useReducer, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  deleteProductImageService,
  deleteProductService,
  getProductCategoriesService,
  getProductService,
  getAffiliateProductService,
  updateProductImageService,
  updateProductService,
  updateAffiliatePixelsService,
  getPaymentMethods,
  updateProductOffers,
  createProductOffer,
  deleteProductOffer,
} from '@/services/products';
import {
  createCourseV2Service,
  getMemberAreaCourseV2,
  deleteCourseV2Service,
} from '@/services/members-v2';
import { getFee } from '@/services/financial';
import { isEqual } from 'lodash';

export const EditProductContext = createContext({
  product: {},
  isFetchingProduct: false,
  refetchProductData: async () => {},
  updateProduct: async () => {},
  isUpdatingProduct: false,
  categories: [],
  isFetchingCategories: false,
  deleteProduct: () => {},
  isDeletingProduct: false,
  cancelSubscriptions: () => {},
  isCancelingSubscriptions: false,
  createOffer: async (data) => {},
  isCreatingOffer: false,
  updateOffer: async (data) => {},
  isUpdatingOffer: false,
  updatePixels: () => {},
  isUpdatingFixels: false,
  differentOffers: false,
  setDifferentOffers: () => {},
  firstError: null,
  setFirstError: () => {},
  paymentMethodsOptions: [],
  isLoadingPaymentMethodsOptions: false,
  onMovePaymentOption: () => {},
  productFees: {},
});

export const ProductType = {
  subscription: {
    value: 'subscription',
    label: 'Assinatura',
  },
  unique: {
    value: 'unique',
    label: 'Pagamento único',
  },
};

const initialState = {
  paymentMethodsOptions: [],
};

const reducer = (state, action) => {
  if (action.type === 'SET_PAYMENT_OPTION') {
    return {
      ...state,
      paymentMethodsOptions: action.payload,
    };
  }

  if (action.type === 'ON_MOVE_PAYMENT_OPTION') {
    const updatedArray = [...state.paymentMethodsOptions];
    const { from, to } = action.payload;

    const movedItem = updatedArray[from];

    updatedArray.splice(from, 1);
    updatedArray.splice(to, 0, movedItem);

    const nubankIndex = updatedArray.findIndex((item) => item.value === 'openfinance_nubank');
    if (nubankIndex > -1) {
      const [nubankItem] = updatedArray.splice(nubankIndex, 1);
      updatedArray.unshift(nubankItem);
    }

    return {
      ...state,
      paymentMethodsOptions: updatedArray,
    };
  }

  return state;
};

const EditProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id, short_id } = useParams();

  const { pathname } = useLocation();

  const isAffiliate = pathname.includes('affiliates');

  const [differentOffers, setDifferentOffers] = useState(false);
  const [firstError, setFirstError] = useState();

  const { data: memberAreaCourseV2, isLoading: isLoadingMemberAreaCourseV2 } = useQuery({
    queryKey: ['member-area-course-v2', { id }],
    queryFn: () => getMemberAreaCourseV2(id),
  });

  const {
    data: productData,
    isLoading: isFetchingProduct,
    refetch: refetchProductData,
  } = useQuery(
    ['product', { id, short_id }],
    () => (isAffiliate ? getAffiliateProductService({ short_id }) : getProductService({ id })),
    {
      enabled: !!id,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      select(data) {
        return {
          ...data,
          offers: data.offers?.map((offer) => ({
            ...offer,
            offerId: offer.id,
            trial_days: offer?.trial_days || 0,
          })),
          bumps: data.bumps?.sort((a, b) => a.position - b.position),
        };
      },
    }
  );

  const product = useMemo(() => {
    if (!productData)
      return {
        offers: [],
      };

    const memberAreaV2ContentDelivery = memberAreaCourseV2 ? ['cakto_v2'] : [];

    return {
      ...productData,
      contentDeliveries: [
        ...(productData?.contentDeliveries || []),
        ...memberAreaV2ContentDelivery,
      ],
    };
  }, [productData, memberAreaCourseV2]);

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    () => getProductCategoriesService(),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const { mutateAsync: updatePixels, isLoading: isUpdatingFixels } = useMutation({
    mutationFn: async (tracking_pixels) => updateAffiliatePixelsService({ id, tracking_pixels }),
    onSuccess(data) {
      const productKey = ['product', { id, short_id }];

      const previous = queryClient.getQueryData(productKey);
      const newData = {
        ...previous,
        tracking_pixels: data,
      };

      queryClient.setQueryData(productKey, newData);
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
    },
    onError(error) {
      const fbErrorMessage = error?.response?.data?.facebook_pixels?.filter(
        (pixel) => Object.keys(pixel).length > 0
      );
      const errorMessage =
        fbErrorMessage[0]?.non_field_errors[0] ||
        error.message ||
        'Erro ao atualizar dados do produto';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const { mutateAsync: createOffer, isLoading: isCreatingOffer } = useMutation({
    mutationFn: async (data) => {
      const result = await createProductOffer({ ...data, product: product.id });

      return result;
    },
    onSuccess: async () => {
      enqueueSnackbar('Oferta criada com sucesso', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Erro ao criar oferta', { variant: 'error' });
    },
  });

  const { mutateAsync: updateOffer, isLoading: isUpdatingOffer } = useMutation({
    mutationFn: async (data) => {
      const result = await updateProductOffers({ id: data.offerId, ...data });

      return result;
    },
    onSuccess: async () => {
      enqueueSnackbar('Oferta atualizada com sucesso', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? 'Erro ao atualizar oferta', { variant: 'error' });
    },
  });

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } = useMutation({
    mutationFn: async ({ image, ...data }) => {
      if (typeof image === 'string') {
        fetch(image)
          .then((response) => response.blob())
          .then(async (blob) => {
            const defaultImage = new File([blob], 'defaultProductCover.svg', {
              type: 'image/svg+xml',
            });

            await updateProductImageService({ id, image: defaultImage });
          });
      }
      if (image instanceof File) await updateProductImageService({ id, image });
      if (product?.image && !image) await deleteProductImageService({ id });

      const contentDeliveriesToRemove = ['cakto_v2'];

      const type = data?.offers.find((offer) => offer.default)?.type;

      const allPaymentsMethods = await getPaymentMethods();

      const subscriptionPaymentMethods = ['pix', 'boleto', 'credit_card'];

      const allowedPaymentMethods =
        type === 'subscription'
          ? subscriptionPaymentMethods
          : allPaymentsMethods.map((item) => item.type);

      const allPaymentMethods = data?.paymentMethods?.filter((paymentMethod) =>
        allowedPaymentMethods.includes(paymentMethod)
      );

      const allPaymentsOrder = state.paymentMethodsOptions
        ?.map(({ value }) => value)
        ?.filter((paymentMethod) => allowedPaymentMethods.includes(paymentMethod));

      const getPaymentMethodsValues = () => {
        if (type === 'subscription') {
          if (!allPaymentMethods?.length) {
            return subscriptionPaymentMethods;
          }
        }
        return allPaymentMethods;
      };

      const getDefaultPaymentMethod = () => {
        if (type === 'subscription') {
          if (!allPaymentMethods?.length) {
            return 'credit_card';
          }
        }

        if (allPaymentMethods.includes(data?.defaultPaymentMethod))
          return data.defaultPaymentMethod;

        return allPaymentMethods.filter((payment) => allowedPaymentMethods.includes(payment))[0];
      };

      const paymentMethods = getPaymentMethodsValues();

      const defaultPaymentMethod = getDefaultPaymentMethod();

      const allData = {
        ...data,
        paymentMethods,
        defaultPaymentMethod,
        contentDeliveries: data.contentDeliveries.filter(
          (contentDelivery) => !contentDeliveriesToRemove.includes(contentDelivery)
        ),
        paymentsOrder: allPaymentsOrder,
        bumps: data?.bumps.map((bump, index) => ({
          ...bump,
          position: index,
        })),
        offers: [],
      };

      const updatedOffers = data.offers
        ?.map((offer) => {
          if (!differentOffers)
            return {
              ...offer,
              delete: offer.default ? undefined : true,
            };

          return offer;
        })
        ?.map((offer) => {
          const { offerId, ...rest } = offer;

          return {
            ...rest,
            id: offerId || offer.id,
          };
        });

      const handleUpdateOffer = async (offer) => {
        const productOffer = product.offers.find((o) => o.id === offer.id);

        const formattedOffer = {
          ...offer,
          offerId: offer.id,
          trial_days: Number(offer.trial_days),
          max_retries: Number(offer.max_retries),
          retry_interval: Number(offer.retry_interval),
        };

        if (productOffer && !isEqual(productOffer, formattedOffer)) {
          await updateProductOffers({ id: offer.offerId, ...formattedOffer });
        }
      };

      const processOffers = async () => {
        const promises = updatedOffers.map(async (offer) => {
          if (offer.delete) {
            await deleteProductOffer({ id: offer.id });
            return;
          }

          if (!offer.id) {
            await createProductOffer({ ...offer, product: product.id });
            return;
          }
          await handleUpdateOffer(offer);
        });

        await Promise.all(promises);
      };

      await processOffers();

      const result = await updateProductService({ ...allData, id });

      if (data.contentDeliveries.includes('cakto_v2')) {
        await createCourseV2Service({ courseId: product?.id });
      }

      if (!data.contentDeliveries.includes('cakto_v2')) {
        deleteCourseV2Service({ courseId: product?.id });
      }

      queryClient.invalidateQueries(['paymentMethods', product?.id]);

      return result;
    },
    onSuccess: async () => {
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
      queryClient.invalidateQueries('products');
    },
    onError: (error) => {
      queryClient.invalidateQueries('products');
      enqueueSnackbar(error.message ?? 'Erro ao atualizar dados do produto', { variant: 'error' });
    },
  });

  const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useMutation(
    () => deleteProductService({ id }),
    {
      onSuccess: () => {
        enqueueSnackbar('Produto deletado com sucesso', { variant: 'success' });
        navigate('/dashboard/products');
        queryClient.invalidateQueries('products');
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao deletar produto', { variant: 'error' });
        queryClient.invalidateQueries('products');
      },
    }
  );

  const { mutateAsync: cancelSubscriptions, isLoading: isCancelingSubscriptions } = useMutation(
    () => new Promise((resolve) => setTimeout(resolve, 1000)),
    {
      onSuccess: () => {
        enqueueSnackbar('Inscrições canceladas com sucesso', { variant: 'success' });
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao cancelar inscrições', { variant: 'error' });
      },
    }
  );

  const { isLoading: isLoadingPaymentMethodsOptions } = useQuery(
    ['paymentMethods', product?.id],
    getPaymentMethods,
    {
      onSuccess: (values) => {
        function loadLabel(label) {
          return {
            pix: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z" />
                  <path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z" />
                </svg>{' '}
                {label}
              </div>
            ),
            boleto: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="-4 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z" />
                </svg>{' '}
                {label}
              </div>
            ),
            credit_card: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="box-border h-8 w-8"
                >
                  <path
                    fill="currentColor"
                    d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            picpay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.963 0.586975V8.12397H24.5V0.586975H16.963ZM18.219 1.84298H23.244V6.86798H18.219V1.84298ZM19.475 3.09898V5.61198H21.988V3.09898H19.475ZM4.27 4.35498V7.52997H7.646C9.788 7.52997 11.004 8.56997 11.004 10.469C11.004 12.416 9.788 13.48 7.646 13.48H4.269L4.27 7.52997H0.5V21.414H4.269V16.654H7.839C12.172 16.654 14.654 14.302 14.654 10.334C14.654 6.56297 12.172 4.35598 7.84 4.35598L4.27 4.35498Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            applepay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5502 20.28C16.5702 21.23 15.5002 21.08 14.4702 20.63C13.3802 20.17 12.3802 20.15 11.2302 20.63C9.79016 21.25 9.03016 21.07 8.17016 20.28C3.29016 15.25 4.01016 7.59 9.55016 7.31C10.9002 7.38 11.8402 8.05 12.6302 8.11C13.8102 7.87 14.9402 7.18 16.2002 7.27C17.7102 7.39 18.8502 7.99 19.6002 9.07C16.4802 10.94 17.2202 15.05 20.0802 16.2C19.5102 17.7 18.7702 19.19 17.5402 20.29L17.5502 20.28ZM12.5302 7.25C12.3802 5.02 14.1902 3.18 16.2702 3C16.5602 5.58 13.9302 7.5 12.5302 7.25Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            googlepay: (
              <div className="flex flex-col items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="35"
                  version="1.1"
                  viewBox="0 0 2350 1600"
                >
                  <g id="Layer_x0020_1">
                    <metadata id="CorelCorpID_0Corel-Layer" />
                    <path
                      id="Base_1_"
                      fill="#FFFFFF"
                      d="M1724.06 1.37l-1100 0c-343.75,0 -625,281.25 -625,625l0 0c0,343.75 281.25,625 625,625l1100 0c343.75,0 625,-281.25 625,-625l0 0c0,-343.75 -281.25,-625 -625,-625z"
                    />
                    <g>
                      <path
                        id="Outline"
                        fill="#3C4043"
                        d="M1724.06 52c77.19,0 152.19,15.31 222.82,45.31 68.43,29.06 129.68,70.63 182.81,123.44 52.81,52.81 94.37,114.37 123.44,182.81 30,70.63 45.31,145.63 45.31,222.81 0,77.19 -15.31,152.19 -45.31,222.82 -29.07,68.43 -70.63,129.68 -123.44,182.81 -52.81,52.81 -114.38,94.37 -182.81,123.44 -70.63,30 -145.63,45.31 -222.82,45.31l-1100 0c-77.19,0 -152.19,-15.31 -222.81,-45.31 -68.44,-29.07 -129.69,-70.63 -182.81,-123.44 -52.82,-52.81 -94.38,-114.38 -123.44,-182.81 -30,-70.63 -45.31,-145.63 -45.31,-222.82 0,-77.18 15.31,-152.18 45.31,-222.81 29.06,-68.44 70.62,-129.69 123.44,-182.81 52.81,-52.81 114.37,-94.38 182.81,-123.44 70.62,-30 145.62,-45.31 222.81,-45.31l1100 0 0 0zm0 -50.63l-1100 0c-343.75,0 -625,281.25 -625,625l0 0c0,343.75 281.25,625 625,625l1100 0c343.75,0 625,-281.25 625,-625l0 0c0,-343.75 -281.25,-625 -625,-625l0 0z"
                      />
                    </g>
                    <g id="G_Pay_Lockup_1_">
                      <g id="Pay_Typeface_3_">
                        <path
                          id="Letter_p_3_"
                          fill="#3C4043"
                          d="M1119.69 670.75l0 189.06 -60 0 0 -466.87 159.06 0c40.31,0 74.69,13.43 102.81,40.31 28.75,26.87 43.13,59.69 43.13,98.44 0,39.68 -14.38,72.5 -43.13,99.06 -27.81,26.56 -62.18,39.69 -102.81,39.69l-99.06 0 0 0.31zm0 -220.31l0 162.81 100.31 0c23.75,0 43.75,-8.12 59.38,-24.06 15.93,-15.94 24.06,-35.32 24.06,-57.19 0,-21.56 -8.13,-40.63 -24.06,-56.56 -15.63,-16.57 -35.32,-24.69 -59.38,-24.69l-100.31 0 0 -0.31z"
                        />
                        <path
                          id="Letter_a_3_"
                          fill="#3C4043"
                          d="M1521.56 529.81c44.38,0 79.38,11.88 105,35.63 25.63,23.75 38.44,56.25 38.44,97.5l0 196.87 -57.19 0 0 -44.37 -2.5 0c-24.68,36.56 -57.81,54.68 -99.06,54.68 -35.31,0 -64.69,-10.31 -88.44,-31.25 -23.75,-20.93 -35.62,-46.87 -35.62,-78.12 0,-33.13 12.5,-59.38 37.5,-78.75 25,-19.69 58.43,-29.38 100,-29.38 35.62,0 65,6.57 87.81,19.69l0 -13.75c0,-20.94 -8.12,-38.44 -24.69,-53.12 -16.56,-14.69 -35.94,-21.88 -58.12,-21.88 -33.44,0 -60,14.06 -79.38,42.5l-52.81 -33.12c29.06,-42.19 72.19,-63.13 129.06,-63.13zm-77.5 231.88c0,15.62 6.56,28.75 20,39.06 13.13,10.31 28.75,15.62 46.56,15.62 25.32,0 47.82,-9.37 67.51,-28.12 19.68,-18.75 29.68,-40.63 29.68,-65.94 -18.75,-14.69 -44.69,-22.19 -78.12,-22.19 -24.38,0 -44.69,5.94 -60.94,17.51 -16.56,12.18 -24.69,26.87 -24.69,44.06z"
                        />
                        <polygon
                          id="Letter_y_3_"
                          fill="#3C4043"
                          points="1991.25,540.12 1791.25,1000.12 1729.38,1000.12 1803.75,839.19 1671.88,540.12 1737.19,540.12 1832.19,769.5 1833.44,769.5 1925.94,540.12 "
                        />
                      </g>
                      <g id="G_Mark_1_">
                        <path
                          id="Blue_500"
                          fill="#4285F4"
                          d="M881.03 632.62c0,-19.56 -1.75,-38.28 -5,-56.28l-251.5 0 0 103.13 144.84 0.03c-5.87,34.31 -24.78,63.56 -53.75,83.06l0 66.91 86.22 0c50.35,-46.59 79.19,-115.47 79.19,-196.85z"
                        />
                        <path
                          id="Green_500_1_"
                          fill="#34A853"
                          d="M715.65 762.56c-24,16.19 -54.9,25.66 -91.06,25.66 -69.84,0 -129.09,-47.06 -150.31,-110.5l-88.94 0 0 69c44.06,87.44 134.63,147.44 239.25,147.44 72.31,0 133.06,-23.79 177.28,-64.72l-86.22 -66.88z"
                        />
                        <path
                          id="Yellow_500_1_"
                          fill="#FABB05"
                          d="M465.9 626.53c0,-17.81 2.97,-35.03 8.38,-51.22l0 -69 -88.94 0c-18.22,36.16 -28.47,76.97 -28.47,120.22 0,43.25 10.28,84.06 28.47,120.22l88.94 -69c-5.41,-16.19 -8.38,-33.41 -8.38,-51.22z"
                        />
                        <path
                          id="Red_500"
                          fill="#E94235"
                          d="M624.59 464.81c39.47,0 74.81,13.6 102.72,40.16l76.41 -76.35c-46.41,-43.21 -106.91,-69.75 -179.13,-69.75 -104.59,0 -195.18,60 -239.25,147.44l88.94 69c21.22,-63.44 80.47,-110.5 150.31,-110.5z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>{' '}
                {label}
              </div>
            ),
            openfinance_nubank: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 500 275.9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M90.3 26.3C106.1 9.7 127 0 151.7 0c48 0 79.8 35 85.9 87.5 2 17.1 2 41.1 1.9 68.9v113h-66v-77.1s-.1-66-.5-78.4c-1.8-53.7-33.6-87.5-82.7-87.6-14.9 15.8-22.8 34.9-24 64.2-.2 4.1-.1 18.6-.1 37.6 0 9.9.1 21 .1 32.6v108.8H.3V150.8c0-4.1-.1-8.2-.1-12.3-.1-8.3-.3-16.6.1-24.9.7-13.8 3.1-27.4 9.6-40 14.7-28.8 44.9-47.4 77-47.4 1.1 0 2.2.1 3.4.1z"
                    fill="currentColor"
                  />
                  <path
                    d="M499.7 162.3c.4-8.3.3-16.6.1-24.9-.1-4.1-.1-8.2-.1-12.3V6.4h-66v108.8c0 11.6 0 22.7.1 32.6.1 19.1.1 33.6-.1 37.6-1.2 29.3-9.1 48.4-23.9 64.1-49.1 0-81-33.8-82.7-87.5-.4-12.3-.6-43-.6-78.4V6.3l-66 .1v113c0 27.8 0 51.9 1.9 68.9 6.1 52.5 37.9 87.5 85.9 87.5 24.6 0 45.6-9.7 61.4-26.3 1.1 0 2.2.1 3.4.1 32.2 0 62.3-18.6 77-47.4 6.5-12.6 8.9-26.1 9.6-39.9z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
          };
        }

        const { paymentsOrder, offers } = product;

        const offerType = offers?.find((offer) => offer.default)?.type;

        const comparePayments = (a, b) => {
          const indexA = paymentsOrder.indexOf(a.type);
          const indexB = paymentsOrder.indexOf(b.type);

          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          }

          if (indexA !== -1) {
            return -1;
          }
          if (indexB !== -1) {
            return 1;
          }

          return 0;
        };

        const allowedPaymentMethods =
          offerType === 'subscription'
            ? ['credit_card', 'boleto', 'pix']
            : values.map(({ type }) => type);

        const result = values
          ?.sort(comparePayments)
          .map(({ type, name }) => ({
            value: type,
            label: loadLabel(name)[type] || name,
            name,
          }))
          .filter(({ value }) => allowedPaymentMethods.includes(value));

        const openFinanceIndex = result.findIndex((tab) => tab.value === 'openfinance_nubank');
        if (openFinanceIndex > -1) {
          const [openFinanceTab] = result.splice(openFinanceIndex, 1);
          result.unshift(openFinanceTab);
        }

        dispatch({
          type: 'SET_PAYMENT_OPTION',
          payload: result,
        });
      },
    }
  );

  const onMovePaymentOption = useCallback((removedIndex, addedIndex) => {
    dispatch({
      type: 'ON_MOVE_PAYMENT_OPTION',
      payload: {
        from: removedIndex,
        to: addedIndex,
      },
    });
  }, []);

  const { data: productFees, isLoading: isLoadingFees } = useQuery({
    queryKey: ['product-fees'],
    queryFn: getFee,
    select(data) {
      const {
        creditCardFixed,
        creditCardPercentage,
        ticketFixed,
        ticketPercentage,
        pixFixed,
        pixPercentage,
        picpayFixed,
        picpayPercentage,
        nupayFixed,
        nupayPercentage,
        googlepayFixed,
        googlepayPercentage,
        applepayFixed,
        applepayPercentage,
      } = data;
      return {
        credit_card: {
          fixed: creditCardFixed || 0,
          percentage: creditCardPercentage || 0,
        },
        boleto: {
          fixed: ticketFixed || 0,
          percentage: ticketPercentage || 0,
        },
        pix: {
          fixed: pixFixed || 0,
          percentage: pixPercentage || 0,
        },
        picpay: {
          fixed: picpayFixed || 0,
          percentage: picpayPercentage || 0,
        },
        nupayFixed: {
          fixed: nupayFixed || 0,
          percentage: nupayPercentage || 0,
        },
        googlepay: {
          fixed: googlepayFixed || 0,
          percentage: googlepayPercentage || 0,
        },
        applepay: {
          fixed: applepayFixed || 0,
          percentage: applepayPercentage || 0,
        },
      };
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  const value = useMemo(
    () => ({
      product,
      isFetchingProduct: isFetchingProduct || isLoadingMemberAreaCourseV2,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      createOffer,
      isCreatingOffer,
      updateOffer,
      isUpdatingOffer,
      setFirstError,
      paymentMethodsOptions: state.paymentMethodsOptions,
      isLoadingPaymentMethodsOptions: isLoadingPaymentMethodsOptions || isLoadingFees,
      onMovePaymentOption,
      productFees,
      refetchProductData,
    }),
    [
      product,
      isFetchingProduct,
      isLoadingMemberAreaCourseV2,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      createOffer,
      updateOffer,
      isUpdatingOffer,
      isCreatingOffer,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
      isLoadingPaymentMethodsOptions,
      state,
      onMovePaymentOption,
      productFees,
      isLoadingFees,
      refetchProductData,
    ]
  );

  return <EditProductContext.Provider value={value}>{children}</EditProductContext.Provider>;
};

EditProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditProductProvider;
