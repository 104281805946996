import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Grid, LinearProgress, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import { fPercent, fShortCurrency } from '../../../utils/formatNumber';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import { NavSectionVertical } from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
//
import { useAuthContext } from '../../../auth/useAuthContext';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import NavToggleButton from './NavToggleButton';
import Navigation from './config-navigation';

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const navConfig = Navigation();

  const { pathname } = useLocation();

  const { user } = useAuthContext();

  // const companies = user?.companies || [];

  // const [company_id, setCompanyId] = useState(companies[0]?.id || '');

  const isDesktop = useResponsive('up', 'lg');

  const totalSales = user?.totalSales || 0;

  const nextAward = +(user?.nextAward?.target_sales || 0);

  const prizeAchieved = totalSales > nextAward;

  const percentConclude = nextAward ? (totalSales / nextAward) * 100 : 100;

  const awards = useMemo(() => {
    if (!nextAward) {
      return fShortCurrency(totalSales);
    }
    return `${fShortCurrency(totalSales, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .replace(/R\$\s?(\d+(?:,\d+)?)\s?mil/g, 'R$ $1K')
      .replace(/(\d+,\d+)k/g, (match) => match.replace(/,/g, '.'))
      .replace(
        /R\$\s?(\d+,\d+)\s?mi\b/g,
        (match, grupo1) => `R$ ${grupo1.replace(',', '.')}M`
      )} / ${fShortCurrency(nextAward, 0)
      ?.replace(/R\$\s?(\d+)\s?mil/g, 'R$ $1K')
      .replace(/R\$\s?(\d+)\s?mi\b/g, 'R$ $1M')}`;
  }, [nextAward, totalSales]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /* useEffect(() => {
    AuthenticatedHttp.interceptors.request.use((config) => {
      if (company_id) config.headers.Http_collaborator_id = company_id;
      return config;
    })
  }, [company_id]); */

  // const onChangeCompany = (value) => setCompanyId(value);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />

        {/*  <Grid item xs={5} md={2.5}>
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="company_id">Empresas</InputLabel>
            <Select
              labelId="company_id"
              id="company_id"
              label="Empresa"
              value={company_id}
              onChange={(e) => onChangeCompany(e.target.value)}
            >
              {companies?.length > 0 && companies.map((_company) => (
                <MenuItem key={_company.id} value={_company.id}>{_company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Box>
          <Grid>
            <Grid>
              <Typography variant="body2" fontWeight="bold">
                {awards}
              </Typography>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={prizeAchieved ? 100 : percentConclude}
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                  {fPercent(percentConclude)}
                </Typography>
              </Box>
            </Box>
            {!nextAward && (
              <Label variant="soft" color="primary" startIcon={<Iconify icon="eva:award-fill" />}>
                Nível máximo!
              </Label>
            )}
          </Grid>
        </Box>
      </Stack>

      <NavSectionVertical data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
