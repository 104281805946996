import PropTypes from 'prop-types';
import { localStorageAvailable } from '@mui/x-data-grid/utils/utils';
import { useQuery, useMutation } from '@tanstack/react-query';
import { createContext, useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { WHATSAPP_ERROR_MESSAGE, WHATSAPP_IN_USE, whatsappValidationEnabled } from '@/consts/auth';
import {
  login as loginService,
  loginSecondStepService,
  me,
  recoveryChangePasswordService,
  recoverySendCodeService,
  register as registerService,
  socialLogin,
} from '@/services/auth';
import {
  updateAmbientService,
  requestUserEmailToken,
  requestUserWhatsappToken,
} from '@/services/user';
import { clearSession, isValidToken, jwtDecode, setSession } from './utils';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  hasTwoFactorAuthenticated: false,
  ephemeralToken: null,
  methods: [],
  isToValidateEmail: false,
  isToValidateWhatsapp: false,
  isToChangeWhatsApp: false,
  isLoading: false,
  userCellphone: null,
  twoFAError: null,
  setTwoFAError: () => {},
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'SET_AUTHENTICATED') {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
    };
  }
  if (action.type === 'SET_USER') {
    return {
      ...state,
      isInitialized: true,
      user: action.payload,
    };
  }
  if (action.type === 'SET_2FA') {
    return {
      ...state,
      hasTwoFactorAuthenticated: action.payload, // Agora recebe o valor diretamente
    };
  }
  if (action.type === 'SET_METHODS_MFA') {
    return {
      ...state,
      methodsMfa: action.payload.methodsMfa,
    };
  }
  if (action.type === 'SET_EPHEMERAL_TOKEN') {
    return {
      ...state,
      ephemeralToken: action.payload.ephemeralToken,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  if (action.type === 'IS_TO_VALIDATE') {
    return {
      ...state,
      isToValidateEmail: action.payload.email,
      isToValidateWhatsapp: action.payload.whatsapp,
      isToChangeWhatsApp: action.payload.changeWhatsApp,
    };
  }

  if (action.type === 'SET_LOADING') {
    return {
      ...state,
      isLoading: action.payload,
    };
  }

  if (action.type === 'SET_USER_CELLPHONE') {
    return {
      ...state,
      userCellphone: action.payload,
    };
  }

  if (action.type === 'SET_VIEW_PAGE_AS') {
    return {
      ...state,
      user: {
        ...state.user,
        viewPageAs: action.payload,
      },
    };
  }

  return state;
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [twoFAError, setTwoFAError] = useState(null);

  const storageAvailable = localStorageAvailable();

  const { refetch } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      try {
        return me();
      } catch (error) {
        return null;
      }
    },
    enabled: state.isAuthenticated,
    refetchInterval: 120000,
  });

  const setAuthenticated = useCallback(() => {
    dispatch({
      type: 'SET_AUTHENTICATED',
    });
  }, []);

  const close2FAModal = useCallback(() => {
    dispatch({
      type: 'SET_2FA',
      payload: false,
    });
  }, []);

  const initialize = useCallback(
    async ({ accessToken, refreshToken, isRegister = false }) => {
      const isValid = await isValidToken(accessToken);

      try {
        if (!accessToken || !isValid) {
          throw new Error('Credenciais inválidas');
        }

        setSession({
          accessToken,
          refreshToken,
        });

        const { data: user } = await refetch();

        if (!user) {
          throw new Error('Credenciais inválidas');
        }

        if (!user.id) {
          user.id = jwtDecode(accessToken).user_id;
        }

        dispatch({
          type: 'SET_USER_CELLPHONE',
          payload: user?.cellphone,
        });

        if (!isRegister) {
          if (!user?.cellphone?.length && user?.is_producer) {
            throw new Error(WHATSAPP_ERROR_MESSAGE);
          }
        }

        dispatch({
          type: 'SET_USER',
          payload: user,
        });

        if (isRegister || !whatsappValidationEnabled || !user.is_producer) {
          user.whatsappValidated = true;
        }

        if (!isRegister) {
          if (!user.emailValidated || !user.whatsappValidated) {
            if (!user.emailValidated && user?.cellphone?.length) {
              await requestUserEmailToken();
            }

            if (!user.whatsappValidated && user.is_producer) {
              try {
                await requestUserWhatsappToken();
              } catch (error) {
                if(error.response.data.detail === WHATSAPP_IN_USE) {
                  state.isToChangeWhatsApp = true;
                  user.whatsappValidated = true;
                };
              }
            }

            dispatch({
              type: 'IS_TO_VALIDATE',
              payload: {
                email: !user.emailValidated,
                whatsapp: !user.whatsappValidated,
                changeWhatsApp: state.isToChangeWhatsApp
              },
            });
          } else {
            setAuthenticated();
          }
        }
      } catch (error) {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });

        const detail =
          error?.message === WHATSAPP_ERROR_MESSAGE
            ? WHATSAPP_ERROR_MESSAGE
            : error?.response?.data?.detail;

        const translatedMessage = detail?.replace(
          /^Pedido foi limitado\. Expected available in (\d+) seconds\.$/,
          'Pedido foi limitado. Estará disponível novamente em $1 segundos.'
        );

        throw new Error(translatedMessage);
      } finally {
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageAvailable]
  );

  useEffect(() => {
    const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';
    initialize({ accessToken, refreshToken });
  }, [initialize, storageAvailable]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const baseUrl = window.location.origin + window.location.pathname;
  const accessToken = urlParams.get('accessToken');
  const refreshToken = urlParams.get('refreshToken');

  useQuery({
    queryKey: [{ baseUrl, accessToken, refreshToken }],
    queryFn: async () => {
      if (accessToken && refreshToken) {
        try {
          await initialize({ accessToken, refreshToken });
        } catch (error) {
          window.location.href = '/auth/login';
        }
      }

      return true;
    },
  });

  // LOGIN
  const login = useCallback(
    async (email, password, recaptchaToken) => {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      dispatch({
        type: 'SET_2FA',
        payload: false, // Reset inicial
      });

      try {
        const data = await loginService({ email, password, recaptchaToken });

        localStorage.setItem('emailForResendCode', email);

        if (data.type === 'direct') {
          await initialize({ accessToken: data.accessToken, refreshToken: data.refreshToken });
        } else {
          // Só ativa 2FA se a resposta for válida e não houver erro
          dispatch({
            type: 'SET_EPHEMERAL_TOKEN',
            payload: { ephemeralToken: data.ephemeralToken },
          });
          dispatch({
            type: 'SET_METHODS_MFA',
            payload: { methodsMfa: data.methods },
          });

          localStorage.setItem('cakto-methods-mfa', JSON.stringify(data.methods));

          dispatch({
            type: 'SET_2FA',
            payload: true,
          });
        }
      } catch (error) {
        // Garante que o 2FA seja resetado em caso de erro
        dispatch({
          type: 'SET_2FA',
          payload: false,
        });
        localStorage.removeItem('emailForResendCode');
        throw error;
      } finally {
        dispatch({
          type: 'SET_LOADING',
          payload: false,
        });
      }
    },
    [initialize]
  );

  const loginSecondStep = useCallback(
    async (ephemeralToken, code) => {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      return loginSecondStepService(ephemeralToken, code)
        .then(async (data) => {
          await initialize({ accessToken: data.accessToken, refreshToken: data.refreshToken });
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          dispatch({
            type: 'SET_LOADING',
            payload: false,
          });
        });
    },
    [initialize]
  );

  const recoverySendCode = useCallback(
    async ({ email, recaptchaToken }) => recoverySendCodeService({ email, recaptchaToken }),
    []
  );

  const recoveryChangePassword = useCallback(
    async ({ token, password }) => recoveryChangePasswordService({ token, password }),
    []
  );

  const updateAmbient = useCallback(async ({ viewPageAs }) => {
    updateAmbientService({ viewPageAs });

    dispatch({
      type: 'SET_VIEW_PAGE_AS',
      payload: viewPageAs,
    });
  }, []);

  const loginWithSocial = useCallback(
    async ({ accessToken: googleAccessToken, provider = 'google' }) =>
      socialLogin({
        access_token: googleAccessToken,
        provider,
      }).then(initialize),
    [initialize]
  );

  const { mutateAsync: register } = useMutation({
    onMutate() {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
    },
    mutationFn: registerService,
    async onSuccess(data) {
      await initialize({ ...data, isRegister: true });
    },
    onSettled() {
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
    },
    onError(err) {
      dispatch({
        type: 'SET_LOADING',
        payload: false,
      });
      throw err;
    },
    // retry: 1,
  });

  // LOGOUT
  const logout = useCallback(() => {
    clearSession();
    dispatch({
      type: 'LOGOUT',
    });
    dispatch({
      type: 'IS_TO_VALIDATE',
      payload: {
        email: false,
        whatsapp: false,
      },
    });
    dispatch({
      type: 'SET_2FA',
      payload: false,
    });
  }, []);

  const setUser = useCallback((user) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      hasTwoFactorAuthenticated: state.hasTwoFactorAuthenticated,
      ephemeralToken: state.ephemeralToken,
      methodsMfa: state.methodsMfa,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      isToValidateEmail: state.isToValidateEmail,
      isToValidateWhatsapp: state.isToValidateWhatsapp,
      isToValidateWhatsappLogin: state.isToValidateWhatsappLogin,
      isToChangeWhatsApp: state.isToChangeWhatsApp,
      userCellphone: state.userCellphone,
      isLoading: state.isLoading,
      method: 'jwt',
      login,
      loginSecondStep,
      loginWithSocial,
      register,
      logout,
      setUser,
      recoverySendCode,
      recoveryChangePassword,
      updateAmbient,
      initialize,
      setAuthenticated,
      twoFAError,
      setTwoFAError,
      close2FAModal,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.hasTwoFactorAuthenticated,
      state.ephemeralToken,
      state.methodsMfa,
      state.user,
      state.isToValidateEmail,
      state.isToValidateWhatsapp,
      state.isToValidateWhatsappLogin,
      state.isToChangeWhatsApp,
      state.userCellphone,
      state.isLoading,
      login,
      loginSecondStep,
      loginWithSocial,
      logout,
      register,
      setUser,
      recoverySendCode,
      recoveryChangePassword,
      updateAmbient,
      initialize,
      setAuthenticated,
      twoFAError,
      setTwoFAError,
      close2FAModal,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
