import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';
import useTableFilter from '@/hooks/useTableFilter';
import { STUDENTS_V2_LIMIT } from '@/consts/productStudents';
import {
  getStudentsService,
  addStudentAccessService,
  removeStudentAccessService,
} from '@/services/members-v2';

export const tableLabels = [
  { id: 'name', label: 'Nome' },
  { id: 'email', label: 'Email' },
  { id: 'lastAccess', label: 'Fim do acesso' },
  { id: 'delete', label: '' },
];

export const StudentsMemberAreaV2Context = createContext({
  table: {},
  count: 0,
  students: [],
  isLoadingStudents: false,
  studentsStatus: '',
  increaseStudentsLimit: () => {},
  addStudentAccess: async () => {},
  isLoadingAddStudentAccess: false,
  removeStudentAccess: async () => {},
  isLoadingRemoveStudentAccess: false,
});

const StudentsMemberAreaV2Provider = ({ children }) => {
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const table = useTableFilter({
    defaultCurrentPage: 1,
    defaultFilter: {},
  });

  const {
    data: studentsData,
    fetchNextPage: increaseStudentsLimit,
    isFetching: isLoadingStudents,
    status: studentsStatus,
  } = useInfiniteQuery({
    queryKey: ['students-v2', table.search, table.filter],
    queryFn: () =>
      getStudentsService({
        id,
        limit: STUDENTS_V2_LIMIT,
        search: table.search,
        filters: table.filter,
        lastEmail:
          studentsData?.pages[studentsData.pages.length - 1]?.results[STUDENTS_V2_LIMIT - 1]?.email,
      }),
    getNextPageParam: (lastPage, pages) => {
      const totalItems = pages?.reduce((acc, { results }) => {
        acc.push(...results);

        return acc;
      }, []);

      if (totalItems?.length >= lastPage?.count) return undefined;

      if (lastPage) {
        return lastPage;
      }

      return undefined;
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });

  const students = useMemo(
    () =>
      studentsData?.pages?.reduce((acc, { results }) => {
        acc.push(...results);

        return acc;
      }, []),
    [studentsData]
  );

  const count = useMemo(() => studentsData?.pages[0].count, [studentsData]);

  const { mutateAsync: addStudentAccess, isLoading: isLoadingAddStudentAccess } = useMutation({
    mutationFn: ({ ...values }) => addStudentAccessService({ id, ...values }),
    onSuccess({ detail }) {
      queryClient.invalidateQueries(['students-v2', table.search, table.filter]);

      enqueueSnackbar(detail, { variant: 'success' });
    },
  });

  const value = useMemo(
    () => ({
      table,
      students,
      count,
      isLoadingStudents,
      studentsStatus,
      increaseStudentsLimit,
      addStudentAccess,
      isLoadingAddStudentAccess,
    }),
    [
      table,
      students,
      count,
      isLoadingStudents,
      studentsStatus,
      increaseStudentsLimit,
      addStudentAccess,
      isLoadingAddStudentAccess,
    ]
  );

  return (
    <StudentsMemberAreaV2Context.Provider value={value}>
      {children}
    </StudentsMemberAreaV2Context.Provider>
  );
};

StudentsMemberAreaV2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StudentsMemberAreaV2Provider;
