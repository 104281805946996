import PropTypes from 'prop-types';
import { createContext, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getMemberAreaCourseV2,
  updateCourseService,
  createModuleService,
  updateModuleService,
  removeModuleService,
  downloadLessonFileService,
  createLessonService,
  updateLessonService,
  removeLessonService,
} from '@/services/members-v2';

export const MemberAreaV2Context = createContext({
  course: [],
  isCourseError: false,
  courseQueryKey: [],
  isLoadingCourse: false,
  expanded: [],
  setExpanded: () => {},
  createModule: async () => {},
  isLoadingCreateModule: false,
  updateCourse: async () => {},
  isLoadingUpdateCourse: false,
  updateModule: async () => {},
  isLoadinUpdateModule: false,
  removeModule: async () => {},
  isLoadingRemoveModule: false,
  uppyStates: {},
  setUppyStates: () => {},
  downloadLessonFile: async () => {},
  isLoadingDownloadLessonFile: false,
  createLesson: async () => {},
  isLoadingCreateLesson: false,
  updateLesson: async () => {},
  isLoadingUpdateLesson: false,
  removeLesson: async () => {},
  isLoadingRemoveLesson: false,
  isSyncing: false,
});

const MemberAreaV2Provider = ({ children }) => {
  const [expanded, setExpanded] = useState([]);
  const [uppyStates, setUppyStates] = useState({});

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const courseQueryKey = ['member-area-course-v2', { id }];

  const { data: course, isLoading: isLoadingCourse } = useQuery({
    queryKey: courseQueryKey,
    queryFn: () => getMemberAreaCourseV2(id),
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  const { mutateAsync: updateCourse, isLoading: isLoadingUpdateCourse } = useMutation({
    mutationFn: updateCourseService,
    onMutate() {
      const previousData = queryClient.getQueryData(courseQueryKey);

      return { previousData };
    },
    onSuccess: () => {
      enqueueSnackbar('Curso atualizado com sucesso', {
        variant: 'success',
      });
    },
    onError: (error, newData, context) => {
      enqueueSnackbar(error.response.data.detail || 'Não foi possível atualizar o curso', {
        variant: 'error',
      });

      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const { mutateAsync: createModule, isLoading: isLoadingCreateModule } = useMutation({
    async onMutate() {
      const previousData = queryClient.getQueryData(courseQueryKey);

      return { previousData };
    },
    mutationFn: createModuleService,
    onSuccess(data) {
      const previous = queryClient.getQueryData(courseQueryKey);

      const module = {
        id: data?.id,
        name: data?.nome,
        order: data?.posicao,
        lessons: [],
      };

      queryClient.setQueryData(courseQueryKey, () => ({
        ...previous,
        modules: [...(previous?.modules || []), module],
      }));

      enqueueSnackbar('Módulo adicionado com sucesso!', {
        variant: 'success',
      });
    },
    onError(data, newData, context) {
      enqueueSnackbar('Não foi possível adicionar o Módulo!', {
        variant: 'error',
      });

      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const { mutateAsync: updateModule, isLoading: isLoadinUpdateModule } = useMutation({
    mutationFn: (data) => updateModuleService({ courseId: id, ...data }),
    async onMutate(data) {
      await queryClient.cancelQueries({ queryKey: courseQueryKey });

      const previousData = queryClient.getQueryData(courseQueryKey);

      const { name, coverImage } = data;

      const newData = {
        ...previousData,
        modules: previousData.modules.map((module) => {
          if (module.id === data.id) {
            return {
              ...module,
              name,
              coverImage,
            };
          }

          return module;
        }),
      };

      queryClient.setQueryData(courseQueryKey, newData);

      return { previousData };
    },
    onSuccess() {
      enqueueSnackbar('Módulo atualizado com sucesso!', {
        variant: 'success',
      });
    },
    onError(data, newData, context) {
      enqueueSnackbar('Não foi possível atualizar o Módulo!', {
        variant: 'error',
      });

      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const { mutateAsync: removeModule, isLoading: isLoadingRemoveModule } = useMutation({
    mutationFn: (data) => removeModuleService({ courseId: id, ...data }),
    async onMutate(data) {
      await queryClient.cancelQueries({ queryKey: courseQueryKey });

      const previousData = queryClient.getQueryData(courseQueryKey);

      const modules = previousData?.modules.filter((module) => module.id !== data.id);

      queryClient.setQueryData(courseQueryKey, () => ({
        ...previousData,
        modules,
      }));

      return { previousData };
    },
    onSuccess: () => {
      enqueueSnackbar('Módulo removido com sucesso!', {
        variant: 'success',
      });
    },
    onError(data, newData, context) {
      enqueueSnackbar('Não foi possível remover o Módulo!', {
        variant: 'error',
      });

      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const { mutateAsync: downloadLessonFile, isLoading: isLoadingDownloadLessonFile } = useMutation({
    mutationFn: downloadLessonFileService,
  });

  const { mutateAsync: createLesson, isLoading: isLoadingCreateLesson } = useMutation({
    mutationFn: ({ moduleId, youtubeUrl, pandaVideoUrl, ...data }) => {
      if (youtubeUrl) data.video = youtubeUrl;

      if (pandaVideoUrl) data.video = pandaVideoUrl;

      const previousData = queryClient.getQueryData(courseQueryKey);

      const currentModule = previousData?.modules?.find((module) => module.id === moduleId);

      const posicao = (currentModule?.lessons?.length || 0) + 1;

      return createLessonService({ courseId: id, moduleId, posicao, ...data });
    },
    onSuccess(result) {
      const previous = queryClient.getQueryData(courseQueryKey);

      queryClient.setQueryData(courseQueryKey, () => ({
        ...previous,
        modules: previous.modules.map((module) => {
          if (module.id === result.moduleId) {
            return {
              ...module,
              lessons: [
                ...module.lessons,
                {
                  id: result.id,
                  title: result.title,
                  order: (module?.lesson?.length || 0) + 1,
                  type: 'lesson',
                  status: 'published',
                },
              ],
            };
          }
          return module;
        }),
      }));
      enqueueSnackbar('Conteúdo adicionado com sucesso!', {
        variant: 'success',
      });
    },
    onError(error) {
      enqueueSnackbar('Não foi possível adicionar o Conteúdo!', {
        variant: 'error',
      });

      queryClient.invalidateQueries(courseQueryKey);
    },
  });

  const { mutateAsync: updateLesson, isLoading: isLoadingUpdateLesson } = useMutation({
    mutationFn: ({ id: lessonId, ...values }) => {
      const { filesKeys, files, youtubeUrl, pandaVideoUrl, ...data } = values;
      const allFiles = files?.reduce((acc, item) => {
        const fileKey = filesKeys?.find((key) => key.includes(item));
        if (fileKey) {
          acc.push(fileKey);
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      const allData = {
        ...data,
        files: allFiles,
        thumbnail: values?.thumbnail || '',
      };

      if (youtubeUrl) allData.video = youtubeUrl;

      if (pandaVideoUrl) allData.video = pandaVideoUrl;

      return updateLessonService({ courseId: id, lessonId, ...allData });
    },
    async onMutate(data) {
      await queryClient.cancelQueries({ queryKey: courseQueryKey });

      const previousData = queryClient.getQueryData(courseQueryKey);

      const newData = {
        ...previousData,
        modules: previousData.modules.map((module) => {
          if (module.id === data.moduleId) {
            return {
              ...module,
              lessons: module.lessons.map((lesson) => {
                if (lesson.id === data.id) {
                  return {
                    ...lesson,
                    ...data,
                  };
                }

                return lesson;
              }),
            };
          }

          return module;
        }),
      };

      queryClient.setQueryData(courseQueryKey, newData);

      return { previousData };
    },
    onSuccess() {
      enqueueSnackbar('Conteúdo atualizado com sucesso!', {
        variant: 'success',
      });
    },
    onError(data, newData, context) {
      enqueueSnackbar('Não foi possível atualizar o Conteúdo!', {
        variant: 'error',
      });

      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const { mutateAsync: removeLesson, isLoading: isLoadingRemoveLesson } = useMutation({
    mutationFn: ({ lessonId }) => removeLessonService({ courseId: id, lessonId }),
    onMutate(data) {
      const { lessonId, moduleId } = data;
      const previousData = queryClient.getQueryData(courseQueryKey);

      const newData = {
        ...previousData,
        modules: previousData.modules.map((module) => {
          if (module.id === moduleId) {
            return {
              ...module,
              lessons: module.lessons.filter((lesson) => lesson.id !== lessonId),
            };
          }

          return module;
        }),
      };

      queryClient.setQueryData(courseQueryKey, newData);

      return { previousData };
    },
    onSuccess() {
      enqueueSnackbar('Conteúdo removido com sucesso!', {
        variant: 'success',
      });
    },
    onError(data, newData, context) {
      enqueueSnackbar('Não foi possível remover o Conteúdo!', {
        variant: 'error',
      });
      queryClient.setQueryData(courseQueryKey, context.previousData);
    },
  });

  const value = useMemo(
    () => ({
      course,
      isCourseError: course === false,
      courseQueryKey: ['member-area-course-v2', { id }],
      isLoadingCourse,
      expanded,
      setExpanded,
      createModule,
      isLoadingCreateModule,
      updateModule,
      isLoadinUpdateModule,
      updateCourse,
      isLoadingUpdateCourse,
      removeModule,
      isLoadingRemoveModule,
      uppyStates,
      setUppyStates,
      downloadLessonFile,
      isLoadingDownloadLessonFile,
      createLesson,
      isLoadingCreateLesson,
      updateLesson,
      isLoadingUpdateLesson,
      removeLesson,
      isLoadingRemoveLesson,
      isSyncing:
        isLoadingCreateModule ||
        isLoadinUpdateModule ||
        isLoadingCourse ||
        isLoadingUpdateCourse ||
        isLoadingRemoveModule ||
        isLoadingCreateLesson ||
        isLoadingUpdateLesson ||
        isLoadingRemoveLesson,
    }),

    [
      course,
      isLoadingCourse,
      id,
      expanded,
      setExpanded,
      createModule,
      isLoadingCreateModule,
      updateModule,
      isLoadinUpdateModule,
      updateCourse,
      isLoadingUpdateCourse,
      removeModule,
      isLoadingRemoveModule,
      downloadLessonFile,
      uppyStates,
      setUppyStates,
      createLesson,
      isLoadingCreateLesson,
      isLoadingDownloadLessonFile,
      updateLesson,
      removeLesson,
      isLoadingRemoveLesson,
      isLoadingUpdateLesson,
    ]
  );

  return <MemberAreaV2Context.Provider value={value}>{children}</MemberAreaV2Context.Provider>;
};

MemberAreaV2Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MemberAreaV2Provider;
