import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const CreatePasswordPage = Loadable(lazy(() => import('../pages/auth/CreatePasswordPage')));

// DASHBOARD: GENERAL
export const DashboardHomePage = Loadable(
  lazy(() => import('../pages/dashboard/DashboardHomePage'))
);
export const MySales = Loadable(lazy(() => import('../pages/dashboard/MySales')));
export const Subscriptions = Loadable(lazy(() => import('../pages/dashboard/Subscriptions')));
export const Collaborators = Loadable(lazy(() => import('../pages/dashboard/Collaborators')));
export const Financial = Loadable(lazy(() => import('../pages/dashboard/Financial')));
export const FinancialCreditCard = Loadable(
  lazy(() => import('../pages/dashboard/Financial/FinancialCreditCard'))
);
export const FinancialCreditCardTransactions = Loadable(
  lazy(() => import('../pages/dashboard/Financial/FinancialCreditCardTransactions'))
);
export const FinancialPixKeys = Loadable(
  lazy(() => import('../pages/dashboard/Financial/FinancialPixKeys'))
);
export const Apps = Loadable(lazy(() => import('../pages/dashboard/Apps')));
export const Reports = Loadable(lazy(() => import('../pages/dashboard/reports/Reports')));
export const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
export const ReportAbandonedSales = Loadable(
  lazy(() => import('../pages/dashboard/ReportAbandonedSales'))
);
export const ReportAffiliatesRevenue = Loadable(
  lazy(() => import('../pages/dashboard/reports/ReportAffiliatesRevenue'))
);
export const ReportCoProductionRevenue = Loadable(
  lazy(() => import('../pages/dashboard/reports/ReportCoProductionRevenue'))
);
export const ReportCoursesEngagement = Loadable(
  lazy(() => import('../pages/dashboard/ReportCoursesEngagement'))
);
export const ReportFinancialEstimate = Loadable(
  lazy(() => import('../pages/dashboard/reports/ReportFinancialEstimate'))
);
export const ReportProductsRevenue = Loadable(
  lazy(() => import('../pages/dashboard/reports/ReportProductsRevenue'))
);
export const Products = Loadable(lazy(() => import('../pages/dashboard/Products')));
// export const ShowcaseCourses = Loadable(lazy(() => import('../pages/dashboard/ShowcaseCourses')));
export const Courses = Loadable(lazy(() => import('../pages/course/Courses')));
export const StudentProfile = Loadable(lazy(() => import('../pages/student/StudentProfile')));
export const StudentNotifications = Loadable(
  lazy(() => import('../pages/student/StudentNotifications'))
);
export const EditProduct = Loadable(lazy(() => import('../pages/dashboard/EditProduct')));
export const ProductMembersArea = Loadable(
  lazy(() => import('../pages/dashboard/ProductMembersArea'))
);
export const ProductMembersAreaV2 = Loadable(
  lazy(() => import('../pages/dashboard/ProductMembersAreaV2'))
);
export const NotificationsPage = Loadable(
  lazy(() => import('../pages/dashboard/NotificationsPage'))
);
export const AffiliatesPage = Loadable(lazy(() => import('../pages/dashboard/AffiliatesPage')));
export const AffiliateInvitePage = Loadable(
  lazy(() => import('../pages/dashboard/AffiliateInvite'))
);
export const CouponsPage = Loadable(lazy(() => import('../pages/dashboard/CouponsPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);
export const CheckoutBuilder = Loadable(
  lazy(() => import('../pages/checkout-builder/CheckoutBuilderPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// DEMO COMPONENTS
// ----------------------------------------------------------------------

export const ComponentsOverviewPage = Loadable(
  lazy(() => import('../pages/components/ComponentsOverviewPage'))
);

// FOUNDATION
export const FoundationColorsPage = Loadable(
  lazy(() => import('../pages/components/foundation/FoundationColorsPage'))
);
export const FoundationTypographyPage = Loadable(
  lazy(() => import('../pages/components/foundation/FoundationTypographyPage'))
);
export const FoundationShadowsPage = Loadable(
  lazy(() => import('../pages/components/foundation/FoundationShadowsPage'))
);
export const FoundationGridPage = Loadable(
  lazy(() => import('../pages/components/foundation/FoundationGridPage'))
);
export const FoundationIconsPage = Loadable(
  lazy(() => import('../pages/components/foundation/FoundationIconsPage'))
);

// MUI COMPONENTS
export const MUIAccordionPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIAccordionPage'))
);
export const MUIAlertPage = Loadable(lazy(() => import('../pages/components/mui/MUIAlertPage')));
export const MUIAutocompletePage = Loadable(
  lazy(() => import('../pages/components/mui/MUIAutocompletePage'))
);
export const MUIAvatarPage = Loadable(lazy(() => import('../pages/components/mui/MUIAvatarPage')));
export const MUIBadgePage = Loadable(lazy(() => import('../pages/components/mui/MUIBadgePage')));
export const MUIBreadcrumbsPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIBreadcrumbsPage'))
);
export const MUIButtonsPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIButtonsPage'))
);
export const MUICheckboxPage = Loadable(
  lazy(() => import('../pages/components/mui/MUICheckboxPage'))
);
export const MUIChipPage = Loadable(lazy(() => import('../pages/components/mui/MUIChipPage')));
export const MUIDataGridPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIDataGridPage'))
);
export const MUIDialogPage = Loadable(lazy(() => import('../pages/components/mui/MUIDialogPage')));
export const MUIListPage = Loadable(lazy(() => import('../pages/components/mui/MUIListPage')));
export const MUIMenuPage = Loadable(lazy(() => import('../pages/components/mui/MUIMenuPage')));
export const MUIPaginationPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIPaginationPage'))
);
export const MUIPickersPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIPickersPage'))
);
export const MUIPopoverPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIPopoverPage'))
);
export const MUIProgressPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIProgressPage'))
);
export const MUIRadioButtonsPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIRadioButtonsPage'))
);
export const MUIRatingPage = Loadable(lazy(() => import('../pages/components/mui/MUIRatingPage')));
export const MUISliderPage = Loadable(lazy(() => import('../pages/components/mui/MUISliderPage')));
export const MUIStepperPage = Loadable(
  lazy(() => import('../pages/components/mui/MUIStepperPage'))
);
export const MUISwitchPage = Loadable(lazy(() => import('../pages/components/mui/MUISwitchPage')));
export const MUITablePage = Loadable(lazy(() => import('../pages/components/mui/MUITablePage')));
export const MUITabsPage = Loadable(lazy(() => import('../pages/components/mui/MUITabsPage')));
export const MUITextFieldPage = Loadable(
  lazy(() => import('../pages/components/mui/MUITextFieldPage'))
);
export const MUITimelinePage = Loadable(
  lazy(() => import('../pages/components/mui/MUITimelinePage'))
);
export const MUITooltipPage = Loadable(
  lazy(() => import('../pages/components/mui/MUITooltipPage'))
);
export const MUITransferListPage = Loadable(
  lazy(() => import('../pages/components/mui/MUITransferListPage'))
);
export const MUITreesViewPage = Loadable(
  lazy(() => import('../pages/components/mui/MUITreesViewPage'))
);

// EXTRA
export const DemoAnimatePage = Loadable(
  lazy(() => import('../pages/components/extra/DemoAnimatePage'))
);
export const DemoCarouselsPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoCarouselsPage'))
);
export const DemoChartsPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoChartsPage'))
);
export const DemoCopyToClipboardPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoCopyToClipboardPage'))
);
export const DemoEditorPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoEditorPage'))
);
export const DemoFormValidationPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoFormValidationPage'))
);
export const DemoImagePage = Loadable(
  lazy(() => import('../pages/components/extra/DemoImagePage'))
);
export const DemoLabelPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoLabelPage'))
);
export const DemoLightboxPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoLightboxPage'))
);
export const DemoMapPage = Loadable(lazy(() => import('../pages/components/extra/DemoMapPage')));
export const DemoMegaMenuPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoMegaMenuPage'))
);
export const DemoMultiLanguagePage = Loadable(
  lazy(() => import('../pages/components/extra/DemoMultiLanguagePage'))
);
export const DemoNavigationBarPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoNavigationBarPage'))
);
export const DemoOrganizationalChartPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoOrganizationalChartPage'))
);
export const DemoScrollbarPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoScrollbarPage'))
);
export const DemoSnackbarPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoSnackbarPage'))
);
export const DemoTextMaxLinePage = Loadable(
  lazy(() => import('../pages/components/extra/DemoTextMaxLinePage'))
);
export const DemoUploadPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoUploadPage'))
);
export const DemoMarkdownPage = Loadable(
  lazy(() => import('../pages/components/extra/DemoMarkdownPage'))
);
export const SubscriptionsTable = Loadable(
  lazy(() => import('../pages/subscription/SubscriptionPage'))
);
// REFUND
export const RefundHome = Loadable(lazy(() => import('../pages/refund/Home')));
export const RefundPurchases = Loadable(lazy(() => import('../pages/refund/Purchases')));
export const RefundPurchase = Loadable(lazy(() => import('../pages/refund/Purchase')));

// MEMBERS
export const MembersDiscordFinishConfigPage = Loadable(
  lazy(() => import('../pages/members/discord/finish-config/MembersDiscordFinishConfigPage'))
);
