import { passwordErrors } from '@/consts/auth';

export function validateCPF(cpf) {
  cpf = cpf?.replace(/\D/g, '');
  if (cpf?.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  let remainder;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++) sum += parseInt(cpf?.substring(i - 1, i), 10) * (11 - i);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf?.substring(9, 10), 10)) return false;

  sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf?.substring(i - 1, i), 10) * (12 - i);
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf?.substring(10, 11), 10)) return false;

  return true;
}

export function validateCNPJ(cnpj) {
  cnpj = cnpj?.replace(/\D/g, '');
  if (cnpj?.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj?.substring(0, length);
  const digits = cnpj?.substring(length);
  let sum = 0;
  let pos = length - 7;

  // eslint-disable-next-line no-plusplus
  for (let i = length; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) return false;

  length += 1;
  numbers = cnpj?.substring(0, length);
  sum = 0;
  pos = length - 7;
  // eslint-disable-next-line no-plusplus
  for (let i = length; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) return false;

  return true;
}

export const validatePasswordErrors = (password) => {
  const errors = [];

  const { min, uppercase, lowercase, number, specialChar } = passwordErrors;

  const remove = (field) => {
    let indexToRemove = -1;

    errors.forEach((item, index) => {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(field)) {
        indexToRemove = index;
      }
    });

    if (indexToRemove !== -1) {
      errors.splice(indexToRemove, 1);
    }
  };

  const minCondition = password?.match(/.{8,}/);
  const upperCaseCondition = password?.match(/[A-Z]/);
  const lowerCaseCondition = password?.match(/[a-z]/);
  const numberCondition = password?.match(/\d/);
  const specialCharCondition = password?.match(/[^a-zA-Z0-9]/);

  if (!minCondition) errors.push({ min });
  if (!upperCaseCondition) errors.push({ uppercase });
  if (!lowerCaseCondition) errors.push({ lowercase });
  if (!numberCondition) errors.push({ number });
  if (!specialCharCondition) errors.push({ specialChar });

  if (minCondition) remove('min');
  if (upperCaseCondition) remove('uppercase');
  if (lowerCaseCondition) remove('lowercase');
  if (numberCondition) remove('number');
  if (specialCharCondition) remove('specialChar');

  return errors;
};

export const getPasswordValidConditions = (value) => {
  const invalidConditions = validatePasswordErrors(value)?.map((error) => Object.values(error)[0]);
  const allConditions = Object.values(passwordErrors);

  const validConditions = allConditions.filter(
    (condition) => !invalidConditions.includes(condition)
  );

  return validConditions;
};
