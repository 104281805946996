import { AuthenticatedHttp } from '@/http/axios';
import { downloadFile } from '@/utils/files';

export const downloadLessonFileService = async (fileKey) =>
  AuthenticatedHttp.get(`/members/v2/lessons/files/?fileKey=${fileKey}`).then((response) => {
    downloadFile({
      url: response.data.url,
      filename: fileKey.replace(/^.*?-/, ''),
    });
  });

const mapLesson = (lesson) => ({
  id: lesson.id,
  title: lesson.nome,
  thumbnail: {
    preview: lesson.capa,
  },
  content: lesson.descricao,
  video: lesson.video,
  order: lesson.posicao,
  type: 'lesson',
  status: 'published',
  moduleId: lesson.moduloId,
  files: lesson?.files?.map((file) => file.replace(/^.*?-/, '')),
  filesKeys: lesson?.files,
  startIn: lesson?.startIn,
  startDays: lesson?.startDays,
  startDate: lesson?.startDate,
});

export const getLessonById = async ({ courseId, lessonId }) =>
  AuthenticatedHttp.get(`/members/v2/${courseId}/lessons/${lessonId}/`).then((response) =>
    mapLesson(response.data)
  );

export const createLessonService = async ({
  courseId,
  moduleId,
  title,
  video,
  content,
  thumbnail,
  files,
  startIn,
  startDays,
  startDate,
  posicao,
}) => {
  const formData = new FormData();
  files?.forEach((file) => {
    formData.append('titulo', `${file.name}-${file?.uuid}`);
    formData.append(`file`, file);
  });

  const lesson = await AuthenticatedHttp.post(
    `/members/v2/${courseId}/modules/${moduleId}/lessons/`,
    {
      video,
      descricao: content,
      nome: title,
      startIn: startIn === 'immediately' ? null : startIn,
      startDays: startDays?.toString(),
      startDate,
      posicao,
    }
  ).then((response) => mapLesson(response.data));

  const { data } = files?.length
    ? await AuthenticatedHttp.post(
        `/members/v2/${courseId}/lessons/${lesson?.id}/files/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    : { data: { files: [] } };

  return {
    ...lesson,
    files: data?.files,
  };
};

export const updateLessonService = async ({
  courseId,
  lessonId,
  title,
  video,
  content,
  thumbnail,
  capa,
  files,
  startIn,
  startDays,
  startDate,
  order,
}) => {
  const formData = new FormData();

  const payloadFiles = files?.filter((file) => file instanceof File) || [];

  payloadFiles.forEach((file) => {
    formData.append('titulo', `${file.name}-${file?.uuid}`);
    formData.append(`file`, file);
  });

  const updatedFiles = files?.filter((file) => typeof file === 'string') || [];

  const lesson = await AuthenticatedHttp.put(`/members/v2/${courseId}/lessons/${lessonId}/`, {
    video,
    descricao: content,
    nome: title,
    startIn,
    startDays: startDays?.toString(),
    startDate,
    posicao: order,
  });

  const { data } = payloadFiles?.length
    ? await AuthenticatedHttp.post(`/members/v2/${courseId}/lessons/${lessonId}/files/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    : { data: { files: [] } };

  return {
    ...lesson,
    files: [...updatedFiles, ...(data?.files || [])],
  };
};

export const removeLessonService = async ({ courseId, lessonId }) =>
  AuthenticatedHttp.delete(`/members/v2/${courseId}/lessons/${lessonId}/`);
