import axios from 'axios';

export const fetchAddressByZipCodeService = async (zipCode) =>
  axios
    .get(`https://viacep.com.br/ws/${zipCode}/json/`)
    .then(({ data: { erro, cep, logradouro, bairro, localidade, uf } }) => {
      if (erro) {
        throw new Error('CEP não encontrado');
      }
      return {
        zipCode: cep,
        address: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf,
      };
    });
