import { useMutation, useQuery } from '@tanstack/react-query';
import { addMonths, parseISO, isSameDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { exportReport, getBalanceReport } from '../services/reports';
import { fDate } from '../utils/formatTime';

export const ReportBalanceContext = createContext({
  table: {},
  balanceReport: [],
  countBalance: 1,
  setType: () => {},
  dates: {},
  setDates: () => {},
  mutateExport: () => {},
  loadingExport: false,
  fetchingBalance: false,
});

const ReportBalanceProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [type, setType] = useState('received');
  const [unformattedDates, setDates] = useState({
    startDate: new Date(),
    endDate: addMonths(new Date(), 1),
  });

  const dates = {
    createdAt__gte: fDate(unformattedDates?.startDate || new Date(), 'yyyy-MM-dd'),
    createdAt__lte: fDate(unformattedDates?.endDate || new Date(), 'yyyy-MM-dd'),
  };

  const table = useTableFilter({
    tableProps: {
      defaultCurrentPage: 1,
      defaultRowsPerPage: 100,
    },
  });

  const { data: reports, isLoading: fetchingBalance } = useQuery({
    queryKey: ['reports/pending_balance', table.page, type, unformattedDates],
    queryFn: () =>
      getBalanceReport({
        page: table.page,
        status: 'pending',
        dates: {
          createdAt__gte: fDate(unformattedDates?.startDate || new Date(), 'yyyy-MM-dd'),
          createdAt__lte: fDate(unformattedDates?.endDate || new Date(), 'yyyy-MM-dd'),
        },
      }),
    select(data) {
      return (
        data
          ?.reduce((acc, item) => {
            const itemDate = parseISO(item.date);

            const existingDate = acc.find((dateGroup) =>
              isSameDay(parseISO(dateGroup.date), itemDate)
            );

            if (existingDate) {
              existingDate.pending += item.pending;
              existingDate.pendingReserve += item.pendingReserve;
              existingDate.chargeback += item.chargeback || 0;
              existingDate.refunded += item.refunded || 0;
              existingDate.med += item.med || 0;
              existingDate.totalWithoutRefunds = item.totalWithoutRefunds || 0;
            } else {
              acc.push({
                date: item.date,
                pending: item.pending,
                pendingReserve: item.pendingReserve,
                chargeback: item.chargeback,
                refunded: item.refunded,
                med: item.med,
                totalWithoutRefunds: item.totalWithoutRefunds,
              });
            }

            return acc;
          }, [])
          .sort((a, b) => new Date(a.date) - new Date(b.date)) || []
      );
    },
  });

  const { mutateAsync: mutateExport, isLoading: loadingExport } = useMutation({
    async mutationFn(extension) {
      await exportReport({
        report: 'pending_balance',
        extension,
        ...dates,
      });
    },
    onSuccess() {
      enqueueSnackbar('Exportação realizada com sucesso', { variant: 'success' });
    },
  });

  const value = useMemo(
    () => ({
      balanceReport: reports || [],
      countBalance: reports?.length || 0,
      fetchingBalance,
      table,
      type,
      setType,
      dates: unformattedDates,
      setDates,
      mutateExport,
      loadingExport,
    }),
    [reports, fetchingBalance, table, type, unformattedDates, setDates, mutateExport, loadingExport]
  );

  return <ReportBalanceContext.Provider value={value}>{children}</ReportBalanceContext.Provider>;
};

ReportBalanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReportBalanceProvider;
