import PropTypes from 'prop-types';
import { AppBar, Toolbar, Stack, Alert, Container, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { IdentityVerificationContext } from '@/contexts/IdentityVerificationContext';
import { useAuthContext } from '@/auth/useAuthContext';

import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../../../config-global';
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
import AccountPopover from './AccountPopover';
import DarkModeSwitch from './DarkModeSwitch';
import NotificationsPopover from './NotificationsPopover';
import Searchbar from './Searchbar';

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { companyData } = useContext(IdentityVerificationContext);
  const { themeLayout } = useSettingsContext();
  const { user } = useAuthContext();

  const { companyStatus } = user || {}; // Default to an empty object if user is null
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <DarkModeSwitch />
        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      {companyStatus && companyStatus === 'pending' && (
        <Stack
          sx={{ backgroundColor: '#FCAB02', cursor: 'pointer', width: '100%' }}
          justifyContent="center"
          alignItems="center"
          onClick={() => navigate('/dashboard/financial?tab=identity')}
        >
          <Alert severity="warning" variant="filled" sx={{ color: 'black', textAlign: 'center' }}>
            Para fazer sua primeira venda, complete seu cadastro
          </Alert>
        </Stack>
      )}

      <Toolbar sx={{ height: 1, px: { lg: 5 } }}>{renderContent}</Toolbar>
    </AppBar>
  );
}
