import { AuthenticatedHttp } from '@/http/axios';

export const getStudentsService = async ({ id, limit = 10, search, lastEmail, filters }) =>
  AuthenticatedHttp.get(`/members/v2/courses/${id}/users/`, {
    params: {
      limit,
      search,
      lastEmail,
      ...filters,
    },
  })
    .then(({ data }) => data)
    .catch(() => ({
      results: [],
      count: 0,
    }));

export const addStudentAccessService = async ({ id, email, access_time }) =>
  AuthenticatedHttp.post(`/members/v2/${id}/user_access/`, {
    email,
    access_time,
  }).then(({ data }) => data);

export const removeStudentAccessService = async ({ id, email }) =>
  AuthenticatedHttp.delete(`/members/v2/${id}/user_access/?email=${email}`, {
    email,
  }).then(({ data }) => data);
