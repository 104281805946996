import { AuthenticatedHttp } from '@/http/axios';

const uploadImage = async ({ url, file }) => {
  const formData = new FormData();

  formData.append('image', file);

  return AuthenticatedHttp.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(({ data }) => ({
    id: data.id,
    preview: data.file,
  }));
};

export const uploadIfNecessary = async ({ url, image }) => {
  if (!image) {
    return Promise.resolve('ok');
    // return AuthenticatedHttp.delete(url);
  }

  if (image instanceof File) {
    image = await uploadImage({ url, file: image });
  }
  return image?.preview;
};
