import { AuthenticatedHttp } from '../../http/axios';
import { uploadIfNecessary } from './upload-image';

export const createModuleService = async ({ courseId, name, coverImage, order }) => {
  const module = await AuthenticatedHttp.post(`/members/v2/${courseId}/modules/`, {
    nome: name,
    posicao: order,
  }).then(({ data }) => data);

  if (coverImage) {
    await uploadIfNecessary({
      url: `/members/v2/${courseId}/modules/${module.id}/cover/`,
      image: coverImage,
    });
  }

  return module;
};

export const updateModuleService = async ({ courseId, id, name, order, coverImage }) => {
  await uploadIfNecessary({
    url: `/members/v2/${courseId}/modules/${id}/cover/`,
    image: coverImage,
  });

  return AuthenticatedHttp.put(`/members/v2/${courseId}/modules/${id}/`, {
    nome: name,
    posicao: order,
  });
};

export const reorderModulesService = async ({ courseId, modules }) => {
  const modulesPromises = modules.map(async (module) =>
    AuthenticatedHttp.put(`/members/v2/${courseId}/modules/${module?.id}/`, {
      nome: module.name,
      posicao: module.order,
      aulas: module?.lessons?.map((lesson) => ({
        id: lesson.id,
        posicao: lesson.order,
      })),
    })
  );

  return Promise.all(modulesPromises);
};

export const removeModuleService = async ({ courseId, id }) => Promise.resolve('ok');
// AuthenticatedHttp.delete(`/members/v2/${courseId}/modules/${id}/`);
