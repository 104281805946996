import PropTypes from 'prop-types';
// @mui
import { Button, Drawer, IconButton, Typography } from '@mui/material';
// components
// hooks
import { Stack } from '@mui/system';
import FormProvider from '../hook-form/FormProvider';
import Iconify from '../iconify/Iconify';

TableFilterDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
  defaultFilter: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default function TableFilterDrawer({
  form,
  defaultFilter,
  onSubmit,
  open,
  onClose,
  children,
}) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 2,
          width: {
            xs: '100%',
            sm: 400,
          },
          bgcolor: 'background.default',
        },
      }}
    >
      <FormProvider
        methods={form}
        onSubmit={form.handleSubmit((data) => {
          onClose();
          return onSubmit(data);
        })}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:chevron-right-fill" />
            </IconButton>
            <Typography variant="subtitle1">Filtros</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              onClick={() => {
                form.reset(defaultFilter);
              }}
            >
              <Iconify icon="eva:refresh-fill" />
            </IconButton>
            <Button variant="contained" color="primary" type="submit" size="small">
              Aplicar
            </Button>
          </Stack>
        </Stack>
        <Stack spacing={2} position="relative">
          {children}
        </Stack>
      </FormProvider>
    </Drawer>
  );
}
