import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { isWithinInterval, parseISO } from 'date-fns';
import { useParams } from 'react-router';
import { saveCheckout } from '@/services/checkout-builder';
import { getCheckout, validateCouponService } from '@/services/product-checkout';
import { getProductService, getPaymentMethods } from '@/services/products';
import { FormScopes } from '@/utils/form';
import { lightThemeValues } from '@/sections/@checkout-builder/constants';

const CheckoutBuilderContext = createContext({
  device: 'desktop',
  setDevice: () => {},
  scope: FormScopes.INDEX,
  setScope: () => {},
  hovered: null,
  setHovered: () => {},
  update: () => {},
  updating: false,
  checkout: null,
  checkouts: [],
  loading: false,
  paymentMethod: '',
  setPaymentMethod: () => {},
  product: {},
  selectedBumps: [],
  setSelectedBumps: () => {},
  couponData: undefined,
  setCouponData: () => {},
  validateCoupon: async () => {},
  isLoadingValidateCoupon: false,
  isLoadingProduct: false,
});

const CheckoutBuilderProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [device, setDevice] = useState('desktop');

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [hovered, setHovered] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState('');

  const [selectedBumps, setSelectedBumps] = useState([]);

  const [couponData, setCouponData] = useState(0);

  const { id } = useParams();

  const { data: checkout, isLoading: loading } = useQuery({
    queryKey: ['checkout-builder', id],
    queryFn: () =>
      getCheckout({
        id,
      }),
    select(data) {
      if (!data?.config) return data;

      const mobileSettings = data.config.mobile?.settings?.text?.color?.primary?.length
        ? {
            ...lightThemeValues,
            ...data?.config?.mobile?.settings,
          }
        : lightThemeValues;
      return {
        ...data,
        config: {
          ...data.config,
          mobile: {
            ...data?.config?.mobile,
            settings: {
              ...mobileSettings,
            },
          },
          desktop: {
            ...data.config.desktop,
            settings: {
              ...lightThemeValues,
              ...data?.config?.desktop?.settings,
            },
          },
        },
      };
    },
    enabled: !!id,
    initialData: null,
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    mutationFn: (data) => saveCheckout({ ...data, id }),
    onSuccess: () => {
      enqueueSnackbar('Checkout salvo com sucesso!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Erro ao salvar checkout!', {
        variant: 'error',
      });
    },
  });

  const productId = checkout?.product?.id;

  const { data: product, isLoading: isLoadingProduct } = useQuery(
    ['product', { productId }],
    async () => {
      const result = await getProductService({ id: productId });
      const paymentMethods = await getPaymentMethods();

      const paymentsOrder = result?.paymentsOrder?.length
        ? result.paymentsOrder
        : paymentMethods?.map(({ type }) => type);

      return {
        ...result,
        paymentsOrder,
      };
    },
    {
      enabled: !!productId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      select(data) {
        return {
          ...data,
          offers: data.offers?.map((offer) => ({
            ...offer,
            offerId: offer.id,
          })),
          bumps: data.bumps?.sort((a, b) => a.position - b.position),
        };
      },
    }
  );

  const { mutateAsync: validateCoupon, isLoading: isLoadingValidateCoupon } = useMutation({
    mutationFn: (coupon) =>
      validateCouponService({
        code: coupon,
        offerId: product?.offers.find((offer) => offer.default)?.id?.split('_')[0],
      }),
    onSuccess: (data) => {
      const { startTime, endTime } = data;
      const now = new Date();
      const lifetimeDate = new Date(8640000000000000);

      if (
        isWithinInterval(now, {
          start: parseISO(startTime),
          end: endTime ? parseISO(endTime) : lifetimeDate,
        })
      ) {
        setCouponData(data);
      } else {
        throw new Error('Cupom inválido');
      }
    },
    onError() {
      setCouponData(undefined);
    },
  });

  const value = useMemo(
    () => ({
      device,
      setDevice,
      scope,
      setScope,
      hovered,
      setHovered,
      update,
      updating,
      checkout,
      loading,
      paymentMethod,
      setPaymentMethod,
      product,
      selectedBumps,
      setSelectedBumps,
      validateCoupon,
      couponData,
      setCouponData,
      isLoadingValidateCoupon,
      isLoadingProduct,
    }),
    [
      checkout,
      device,
      hovered,
      loading,
      scope,
      update,
      updating,
      paymentMethod,
      setPaymentMethod,
      product,
      selectedBumps,
      setSelectedBumps,
      validateCoupon,
      couponData,
      setCouponData,
      isLoadingValidateCoupon,
      isLoadingProduct,
    ]
  );

  return (
    <CheckoutBuilderContext.Provider value={value}>{children}</CheckoutBuilderContext.Provider>
  );
};

CheckoutBuilderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CheckoutBuilderContext, CheckoutBuilderProvider };
